import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  resume_id: yup.string().required().label('CV ID'),
  characteristic_id: yup
    .array()
    .of(yup.string())
    .required()
    .label('Kenmerk ID'),
  order: yup.number().required().label('Volgorde'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  resume_id: {
    readonly: true,
  },
  characteristic_id: {
    readonly: true,
  },
  order: {
    readonly: true,
  },
};

export const collection = 'employee_characteristics';

export const EmployeeResumeCharacteristic: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.characteristic_id.join(','),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EmployeeResumeCharacteristic = RecordType;
