import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  resume_id: yup.string().required().label('CV ID'),
  job_title: yup.string().required().label('Functie'),
  company: yup.string().required().label('Bedrijf'),
  description: multilingualSchema(yup.string()).label('Omschrijving'),
  start_date: yup.date().required().label('Startdatum'),
  end_date: yup.date().nullable().label('Einddatum'),
  years_only: yup.boolean().nullable().label('Alleen jaartallen'),
  tech_stack: yup.array().of(yup.string()).label('Tech stack'),
  preview_url: yup.string().label('Preview URL'),
  repo_url: yup.string().label('Repo URL'),
  logo: yup.string().label('Logo'),
  order: yup.number().nullable().label('Volgorde').default(1),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  resume_id: {
    readonly: true,
  },
  description: {
    textarea: true,
  },
  logo: {
    file: true,
    basePath: 'logo/',
  },
};

export const collection = 'employee_resume_job';

export const EmployeeResumeJob: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.company,
};

export type EmployeeResumeJobType = RecordType;
