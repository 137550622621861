import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { HolderOutlined } from '@ant-design/icons';
import { CSS } from '@dnd-kit/utilities';
import type { SortableItemType } from './SortableList';
import classes from './SortableListItem.module.css';

export type SortableItemProps = SortableItemType;

export const SortableItem: React.ComponentType<SortableItemProps> = ({
  id,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={classes.wrapper}
    >
      <div {...listeners} className={classes.handle}>
        <HolderOutlined />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export const SortableItemDummy: React.ComponentType<SortableItemProps> = ({
  children,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.handle}>
        <HolderOutlined />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
