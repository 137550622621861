import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './TitleWithLocation.styles';

export type TitleWithLocationProps = {
  title: string;
  location?: string;
  size?: 'large' | 'small';
};

export const TitleWithLocation: React.ComponentType<TitleWithLocationProps> = ({
  title,
  location,
  size = 'large',
}) => (
  <View style={{ ...styles.wrapper, ...styles[size] }}>
    <Text>{title}</Text>
    {location ? <Text style={styles.at}>@</Text> : null}
    {location ? <Text>{location}</Text> : null}
  </View>
);
