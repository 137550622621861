import React from 'react';
import { Input } from 'antd';
import { FieldProps } from '.';

const TextAreaField = ({ value, onChange, readonly }: FieldProps<string>) => {
  return (
    <Input.TextArea
      rows={10}
      disabled={readonly}
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default TextAreaField;
