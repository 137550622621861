import React from 'react';
import { View, Canvas, Image } from '@react-pdf/renderer';
import { styles } from './ProfilePicture.styles';

export type ProfilePictureProps = {
  imageURL: string;
  style?: any;
};

export const ProfilePicture: React.ComponentType<ProfilePictureProps> = ({
  imageURL,
  style,
}) => (
  <View style={style}>
    <Image
      src={imageURL}
      cache={false}
      style={{ width: '100%', height: '100%' }}
    />
    <Canvas
      style={styles.canvas}
      paint={(painter, availableWidth, availableHeight): null => {
        const width = availableWidth;
        const height = availableHeight;
        const offset = 10;
        painter.fillColor('#13ca91');

        //Top left
        painter
          .moveTo(offset, offset)
          .lineTo(width / 2, offset)
          .quadraticCurveTo(offset, offset, offset, height / 2)
          .lineTo(offset, offset)
          .fill();

        //Top right
        painter
          .moveTo(width / 2, offset)
          .lineTo(width - offset, offset)
          .lineTo(width - offset, height / 2)
          .quadraticCurveTo(width - offset, offset, width / 2, offset)
          .fill();

        //Bottom left
        painter
          .moveTo(offset, height / 2)
          .lineTo(offset, height - offset)
          .lineTo(width / 2, height - offset)
          .quadraticCurveTo(offset, height - offset, offset, height / 2)
          .fill();

        //Bottom right
        painter
          .moveTo(width - offset, height / 2)
          .lineTo(width - offset, height - offset)
          .lineTo(width / 2, height - offset)
          .quadraticCurveTo(
            width - offset,
            height - offset,
            width - offset,
            height / 2
          )
          .fill();

        painter
          .circle(width / 2, height / 2, width / 2 - offset)
          .lineWidth(3)
          .lineCap('square')
          .strokeColor('#f8f8f8')
          .stroke();

        return null;
      }}
    />
  </View>
);
