import i18n from 'i18next';
import { format } from 'fecha';
import { Job, Project } from '../../../types/Resume.types';
import { TemplateJob, TemplateProject } from '../DocxResume.types';
import { flattenSkill } from './flattenSkill';

function createTimeSpan(startDate: Date, endDate?: Date): string {
  const formattedStartDate = format(
    startDate,
    i18n.t('resume.sections.jobs.dateFormat')!
  );
  const formattedEndDate = endDate
    ? format(endDate, i18n.t('resume.sections.jobs.dateFormat')!)
    : i18n.t('resume.sections.jobs.present');

  return `${formattedStartDate} - ${formattedEndDate}`;
}

function mapProjectToTemplate(project: Project): TemplateProject {
  const { description, repoURL, previewURL, startDate, endDate } = project;

  return {
    project: project.project,
    description,
    repoURL,
    previewURL,
    //@ts-ignore
    techStack: project.techStack.map(flattenSkill).join(', '),
    ...(startDate && {
      timeSpan: createTimeSpan(
        new Date(startDate),
        endDate ? new Date(endDate) : undefined
      ),
    }),
  };
}

export function mapJobToTemplate(job: Job): TemplateJob {
  const { company, jobTitle, description, previewURL, repoURL } = job;
  return {
    company,
    jobTitle,
    description,
    previewURL,
    repoURL,
    //@ts-ignore
    techStack: job.techStack?.map(flattenSkill).join(', '),
    timeSpan: createTimeSpan(
      new Date(job.startDate),
      job.endDate ? new Date(job.endDate) : undefined
    ),
    ...(job.projects && { projects: job.projects.map(mapProjectToTemplate) }),
  };
}
