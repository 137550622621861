import React from 'react';
import { FieldProps } from '../Form/fields';
import { SkillAutoComplete } from '../SkillAutoComplete/SkillAutoComplete';
import { Skill, SkillType } from '../../models/Skill';
import SkillList from '../SkillList/SkillList';
import useCollection from '../../hooks/useCollection';

export const SkillFormField = ({
  value = [],
  onChange,
  readonly,
  errors,
}: FieldProps<string[]>) => {
  const handleSelect = (item: SkillType) => {
    onChange([...value, item.id]);
  };
  const [allSkills, isDone] = useCollection(Skill);

  const resumeSkills = value
    .map((skill) => {
      return allSkills.find((s) => s.id === skill);
    })
    .filter(Boolean) as SkillType[];

  if (!isDone) {
    return null;
  }

  async function handleOrderChange(items: SkillType[]) {
    const orderedTechstackSkills = items.map((item) => item.id);
    onChange(orderedTechstackSkills);
  }

  const handleRemove = (id: string) =>
    onChange(value.filter((item) => item !== id));

  return (
    <>
      <div>
        <SkillAutoComplete onSelect={handleSelect} exclude={value} />
      </div>
      <div style={{ paddingTop: 12 }}>
        <SkillList
          compact
          skills={resumeSkills}
          onOrderChange={handleOrderChange}
          onDelete={handleRemove}
        />
      </div>
    </>
  );
};

export default SkillFormField;
