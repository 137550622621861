import { ColumnType } from 'antd/lib/table';
import { getValueInCurrentLanguage } from '../../../helpers/getValueInLanguage';

export const multiLingualColumn = <T>(
  inputColumn: ColumnType<T>,
  key: keyof T
) => {
  const column: ColumnType<T> = {
    render: (item) => getValueInCurrentLanguage(item),
    sorter: (a, b) =>
      getValueInCurrentLanguage(a[key] as any)
        .toString()
        .localeCompare(getValueInCurrentLanguage(b[key] as any).toString()),
    ...inputColumn,
  };

  return column;
};
