import {
  getEmployee,
  getEmployeeLanguages,
  getEmployeeSkills,
  getResumeGeneral,
  getResumeCharacteristics,
  getEmployeeEducation,
  getResumeJobs,
  getResumeProjects,
  getResumeFineTuning,
  getResumeQuotes,
} from './genericGetters';
import {
  Person,
  Language,
  Skills,
  Education,
  Job,
  Project,
  Skill,
  ResumeData,
  SectionFineTuning,
  Quote,
} from '../types/Resume.types';
import {
  getValueInLanguageSafe,
  getValueInLanguage,
} from './getValueInLanguage';
import { SkillType } from '../models/Skill';
import { flattenSkill } from '../components/docx-resume/library/flattenSkill';

const anonymousValue = (
  firstName: string,
  lastName: string,
  value: string
): string => {
  return value.split(firstName).join('X').split(lastName).join('');
};

export const getPerson = async (
  resumeId: string,
  employeeId: string,
  anonymous: boolean,
  language: string
): Promise<Person> => {
  const [employee, languages, general, characteristics] = await Promise.all([
    getEmployee(employeeId),
    getEmployeeLanguages(employeeId),
    getResumeGeneral(resumeId),
    getResumeCharacteristics(resumeId),
  ]);

  return {
    name: anonymous
      ? general.jobTitle
      : `${employee.firstName} ${employee.lastName}`,
    firstName: employee.firstName,
    lastName: employee.lastName,
    jobTitle: anonymous ? '' : general.jobTitle,
    dateOfBirth: new Date(employee.dateOfBirth!).toISOString(),
    cityOfResidence: employee.cityOfResidence,
    nationality: getValueInLanguageSafe(employee.nationality, language),
    spokenLanguages: languages.map<Language>((item) => ({
      language: getValueInLanguageSafe(item.language, language),
      proficiency: item.level,
    })),
    imageURL:
      anonymous || Boolean(employee.profileImage) === false
        ? '/assets/stock-photo.jpg'
        : employee.profileImage,
    githubUrl: anonymous ? undefined : employee.githubUrl,
    characteristics: characteristics.map((item) =>
      getValueInLanguageSafe(item.label, language)
    ),
  };
};

const getSkills = async (resumeId: string): Promise<Skills> => {
  const skills: (SkillType & { priority?: boolean })[] =
    await getEmployeeSkills(resumeId);

  const sortedSkills = skills.map((item) => ({
    label: item.name,
    type: item.type,
  }));

  return sortedSkills.map(flattenSkill);
};

const getEductaion = async (
  employeeId: string,
  language: string
): Promise<Education[]> => {
  const education = await getEmployeeEducation(employeeId);

  return education
    .map((item) => ({
      endYear: item.end_year,
      graduated: item.graduated,
      name: getValueInLanguageSafe(item.name, language),
      school: getValueInLanguageSafe(item.school, language),
      startYear: item.start_year,
      profile: getValueInLanguage(item.profile, language),
      note: getValueInLanguage(item.note, language),
    }))
    .sort((a, b) => b.startYear - a.startYear);
};

const getProjects = async (
  jobId: string,
  anonymous: boolean,
  person: Person,
  language: string
): Promise<Project[]> => {
  const projects = await getResumeProjects(jobId);

  return projects.map((item) => ({
    description: anonymous
      ? anonymousValue(
          person.firstName,
          person.lastName,
          getValueInLanguageSafe(item.description, language)
        )
      : getValueInLanguageSafe(item.description, language),
    project: getValueInLanguageSafe(item.project_name, language),
    techStack: item.tech_stack
      .map<Skill>((skill) => ({
        label: skill.name,
        type: skill.type,
      }))
      .map(flattenSkill),
    endDate: item.end_date ? new Date(item.end_date).toISOString() : undefined,
    previewURL: item.preview_url,
    repoURL: item.repo_url,
    startDate: item.start_date
      ? new Date(item.start_date).toISOString()
      : undefined,
    logo: item.logo,
  }));
};

const getJobs = async (
  resumeId: string,
  anonymous: boolean,
  person: Person,
  language: string
): Promise<Job[]> => {
  const jobs = await getResumeJobs(resumeId);

  const withProjects = await Promise.all(
    jobs.map<Promise<Job>>(async (item) => ({
      company: item.company,
      jobTitle: item.job_title,
      startDate: new Date(item.start_date).toISOString(),
      description: anonymous
        ? anonymousValue(
            person.firstName,
            person.lastName,
            getValueInLanguageSafe(item.description, language)
          )
        : getValueInLanguageSafe(item.description, language),
      endDate: item.end_date
        ? new Date(item.end_date).toISOString()
        : undefined,
      techStack: item.tech_stack
        .map<Skill>((skill) => ({
          label: skill.name,
          type: skill.type,
        }))
        .map(flattenSkill),
      previewURL: item.preview_url,
      repoURL: item.repo_url,
      projects: await getProjects(item.id, anonymous, person, language),
      logo: item.logo,
    }))
  );

  return withProjects;
};

const getIntro = async (
  resumeId: string,
  anonymous: boolean,
  person: Person,
  language: string
): Promise<string> => {
  const data = await getResumeGeneral(resumeId);

  const introduction = getValueInLanguageSafe(data.introduction, language);

  return anonymous
    ? anonymousValue(person.firstName, person.lastName, introduction)
    : introduction;
};

const getQuotes = async (
  resumeId: string,
  language: string
): Promise<Quote[]> => {
  const quotes = await getResumeQuotes(resumeId);

  return quotes.map((quote) => ({
    quote: getValueInLanguageSafe(quote.quote, language),
    quoteAuthor: quote.quote_author,
    quoteAuthorTitle: quote.quote_author_title,
  }));
};

const getFineTune = async (
  resumeId: string,
  language: string
): Promise<SectionFineTuning> => {
  const data = await getResumeFineTuning(resumeId);

  return {
    quotesOffset: getValueInLanguage(data?.quotes_offset, language),
    characteristicsOffset: getValueInLanguage(
      data?.characteristics_offset,
      language
    ),
    skillsOffset: getValueInLanguage(data?.skills_offset, language),
    experienceOffset: getValueInLanguage(data?.experience_offset, language),
    educationOffset: getValueInLanguage(data?.education_offset, language),
  };
};

export const getResumeData = async (
  resumeId: string,
  employeeId: string,
  anonymous: boolean,
  language: string
): Promise<ResumeData> => {
  const person = await getPerson(resumeId, employeeId, anonymous, language);
  const [intro, skills, education, jobs, quotes, sectionFineTuning] =
    await Promise.all([
      getIntro(resumeId, anonymous, person, language),
      getSkills(resumeId),
      getEductaion(employeeId, language),
      getJobs(resumeId, anonymous, person, language),
      getQuotes(resumeId, language),
      getFineTune(resumeId, language),
    ]);

  return {
    intro,
    person,
    skills,
    education,
    experience: jobs,
    quotes,
    sectionFineTuning,
  };
};
