import React from 'react';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import {
  updateOrCreate,
  createNew,
} from '../../../../components/Form/helpers/createOrUpdate';
import useQuery from '../../../../hooks/useQuery';
import { EmployeeResumeFineTune } from '../../../../models/EmployeeResumeFineTune';

export type ResumeFineTuningProps = {
  resumeId: string;
  onUpdate: () => void;
};

const ResumeFineTuning: React.ComponentType<ResumeFineTuningProps> = ({
  resumeId,
  onUpdate,
}) => {
  const query = useQuery(EmployeeResumeFineTune).where(
    'resume_id',
    '==',
    resumeId
  );
  const [items, isDone] = useCollection(EmployeeResumeFineTune, query);
  const [item] = items;

  return (
    <>
      {isDone && (
        <Form
          center={false}
          onSubmit={async (updatedValue) => {
            await updateOrCreate(EmployeeResumeFineTune, updatedValue);
            onUpdate();
          }}
          onCancel={() => {}}
          value={() => {
            return item
              ? item
              : { ...createNew(EmployeeResumeFineTune), resume_id: resumeId };
          }}
          schema={EmployeeResumeFineTune.schema}
          schemaDescription={EmployeeResumeFineTune.schemaDescription}
          getFormProperties={() => EmployeeResumeFineTune.formProperties}
        />
      )}
    </>
  );
};

export default ResumeFineTuning;
