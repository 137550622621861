import React from 'react';
import { Card } from 'antd';
import { EmployeeResumeJobType } from '../../../../models/EmployeeResumeJob';
import { useTranslation } from 'react-i18next';
import { format } from 'fecha';
import { getValueInCurrentLanguage } from '../../../../helpers/getValueInLanguage';

export type ResumeOrderJobProps = {
  job: EmployeeResumeJobType;
  [key: string]: any;
};

export const ResumeOrderJob: React.ComponentType<ResumeOrderJobProps> = ({
  job,
  children,
  ...rest
}) => {
  const [t] = useTranslation();
  const formattedStartDate = format(
    new Date(job.start_date),
    t('resume.sections.jobs.dateFormat')!
  );
  const formattedEndDate = job.end_date
    ? format(new Date(job.end_date), t('resume.sections.jobs.dateFormat')!)
    : t('resume.sections.jobs.present');
  const title = `${job.job_title} @ ${job.company}`;
  const description = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <Card {...rest}>
      <Card.Meta
        title={title}
        description={description}
        style={{ marginBottom: 20 }}
      />
      {job.description && (
        <div style={{ marginBottom: 20 }}>
          {getValueInCurrentLanguage(job.description)}
        </div>
      )}
      {children}
    </Card>
  );
};
