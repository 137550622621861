import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import {
  multilingualSchema,
  multilingualDefault,
} from './helpers/multilingualSchema';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  resume_id: yup.string().required().label('CV ID'),
  quotes_offset: multilingualSchema(yup.number()).label('Quotes offset'),
  characteristics_offset: multilingualSchema(yup.number()).label(
    'Kenmerken offset'
  ),
  education_offset: multilingualSchema(yup.number()).label('Opleiding offset'),
  experience_offset: multilingualSchema(yup.number()).label('Ervaring offset'),
  skills_offset: multilingualSchema(yup.number()).label('Skills offset'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  resume_id: {
    readonly: true,
  },
  quotes_offset: {
    default: multilingualDefault(0),
  },
  characteristics_offset: {
    default: multilingualDefault(0),
  },
  education_offset: {
    default: multilingualDefault(0),
  },
  experience_offset: {
    default: multilingualDefault(0),
  },
  skills_offset: {
    default: multilingualDefault(0),
  },
};

export const collection = 'employee_resume_fine_tune';

export const EmployeeResumeFineTune: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.id,
};

export type EmployeeResumeFineTuneType = RecordType;
