import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

interface CollectionTableProps<T extends { id: string }> {
  values: T[];
  columns: ColumnsType<T>;
  Expander: ({
    value,
    onClose,
  }: {
    value: T;
    onClose: () => void;
  }) => JSX.Element;
}
const CollectionTable = <T extends { id: string }>({
  values,
  columns,
  Expander,
}: CollectionTableProps<T>) => {
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
  const data = values.map((item) => ({ ...item, key: item.id }));

  const expandedRowRender = (record: T) => {
    if (expandedRows.indexOf(record.id) === -1) {
      return null;
    }
    return (
      <Expander
        value={record}
        onClose={() =>
          setExpandedRows(expandedRows.filter((r) => r !== record.id))
        }
      />
    );
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      expandedRowKeys={expandedRows}
      onExpandedRowsChange={(values) => setExpandedRows(values as string[])}
      expandable={{
        expandedRowRender: expandedRowRender,
      }}
    />
  );
};

export default CollectionTable;
