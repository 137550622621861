import React from 'react';
import { View } from '@react-pdf/renderer';
import { styles } from './Header.styles';
import { Logo } from '../Logo/Logo';

export const Header: React.ComponentType<{}> = () => {
  return (
    <View fixed style={styles.wrapper}>
      <View>
        <Logo color="black" width={80} />
      </View>
    </View>
  );
};
