import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import CopyOutlined from '@ant-design/icons/CopyOutlined';

interface DuplicateRecordButtonProps {
  duplicateFunction: () => void;
}
const DuplicateRecordButton = ({
  duplicateFunction,
}: DuplicateRecordButtonProps) => (
  <Popconfirm
    placement="left"
    cancelText="Annuleren"
    title={'Weet je het zeker?'}
    onConfirm={duplicateFunction}
  >
    <Tooltip title="Dupliceren">
      <Button shape="circle">
        <CopyOutlined />
      </Button>
    </Tooltip>
  </Popconfirm>
);

export default DuplicateRecordButton;
