import React from 'react';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import {
  updateOrCreate,
  createNew,
} from '../../../../components/Form/helpers/createOrUpdate';
import { EmployeeResumeQuotes } from '../../../../models/EmployeeResumeQuote';
import useQuery from '../../../../hooks/useQuery';

export type EmployeeResumeQuotesFormProps = {
  resumeId?: string;
};

const ResumeQuotes: React.ComponentType<EmployeeResumeQuotesFormProps> = ({
  resumeId,
}) => {
  const query = useQuery(EmployeeResumeQuotes).where(
    'resume_id',
    '==',
    resumeId
  );
  const [items, isDone] = useCollection(EmployeeResumeQuotes, query);
  const [item] = items;

  return (
    <>
      {isDone && (
        <Form
          center={false}
          onSubmit={(updatedValue) => {
            updateOrCreate(EmployeeResumeQuotes, updatedValue);
          }}
          onCancel={() => {}}
          value={() => {
            return item
              ? item
              : { ...createNew(EmployeeResumeQuotes), resume_id: resumeId };
          }}
          schema={EmployeeResumeQuotes.schema}
          schemaDescription={EmployeeResumeQuotes.schemaDescription}
          getFormProperties={() => EmployeeResumeQuotes.formProperties}
        />
      )}
    </>
  );
};

export default ResumeQuotes;
