import React from 'react';
import { View, Image } from '@react-pdf/renderer';
import { Project as ProjectType } from '../../../../../../types/Resume.types';
import { ExperienceTimeSpan } from '../ExperienceTimeSpan/ExperienceTimeSpan';
import { Description } from '../Description/Description';
import { Details } from '../Details/Details';
import { styles } from './Project.styles';
import { TitleWithLocation } from '../../../../TitleWithLocation/TitleWithLocation';

export type ProjectProps = ProjectType;

export const Project: React.ComponentType<ProjectProps> = ({
  project,
  description,
  startDate,
  endDate,
  techStack,
  previewURL,
  repoURL,
  logo,
}) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.projectHeaderWrapper}>
        <View style={styles.projectHeader}>
          <TitleWithLocation title={project} size="small" />
          {startDate ? (
            <ExperienceTimeSpan startDate={startDate} endDate={endDate} />
          ) : null}
        </View>
        {logo ? (
          <View style={styles.logoWrapper}>
            <Image src={logo} style={{ maxWidth: 40, maxHeight: 16 }} />
          </View>
        ) : null}
      </View>
      {description ? <Description>{description}</Description> : null}
      {techStack && techStack.length > 0 ? (
        <Details
          techStack={techStack}
          previewURL={previewURL}
          repoURL={repoURL}
        />
      ) : null}
    </View>
  );
};
