import React from 'react';
import { styles } from './Description.styles';
import { Paragraph } from '../../../../Paragraph/Paragraph';

export type DescriptionProps = {
  style?: any;
  children: React.ReactNode;
};

export const Description: React.ComponentType<DescriptionProps> = ({
  style = {},
  children,
}) => <Paragraph style={{ ...styles.wrapper, ...style }}>{children}</Paragraph>;
