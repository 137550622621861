import React from 'react';
import { AutoComplete } from 'antd';
import { useTranslation } from 'react-i18next';
import { SkillType, Skill } from '../../models/Skill';
import useCollection from '../../hooks/useCollection';

export type SkillAutoCompleteProps = {
  exclude?: string[];
  onSelect: (skill: SkillType) => void;
};

export const SkillAutoComplete: React.ComponentType<SkillAutoCompleteProps> = ({
  exclude = [],
  onSelect,
}) => {
  const [t] = useTranslation();
  const [skills] = useCollection(Skill);
  const [value, setValue] = React.useState('');
  const filteredSkills = skills.filter(({ id }) => !exclude.includes(id));
  const options = filteredSkills.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const handleSelect = (value: string) => {
    const [skill] = skills.filter(({ id }) => id === value);
    onSelect(skill);
    setValue('');
  };

  return (
    <AutoComplete
      options={options}
      value={value}
      placeholder={t('skillAutoComplete.placeholder')}
      onChange={setValue}
      onSelect={handleSelect}
      style={{ minWidth: 150 }}
      filterOption={(inputValue, option) => {
        return option?.label && typeof option?.label === 'string'
          ? option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          : true;
      }}
    />
  );
};
