import * as yup from 'yup';
import { LANGUAGES } from '../../constants/languages';

export const multilingualSchema = (valueType: yup.Schema<any>) =>
  yup.array().of(
    yup.object().shape({
      value: valueType,
      language: yup.string().required(),
    })
  );

export const multilingualDefault = (
  defaultValue: string | number | boolean
) => {
  return LANGUAGES.map(({ language }) => ({
    language,
    value: defaultValue,
  }));
};
