import React from 'react';
import './style.css';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { UserProvider } from './context/userContext';
import { Layout } from './components/Layout/Layout';
import { Routes } from './Routes';
import { Login } from './pages/Login/Login';

import { ConfigProvider } from 'antd';

function App() {
  const { initializing, user, authorized } = useAuth();

  return user && authorized ? (
    <UserProvider value={{ user, initializing }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#13ca91',
            fontFamily: 'Inter, serif',
            fontSize: 16,
          },
          components: {
            Layout: {
              headerBg: '#f0f2f5',
              bodyBg: '#fff',
              fontSize: 20,
            },
            Anchor: {
              colorLink: '#000',
            },
          },
        }}
      >
        <BrowserRouter>
          <Layout>
            <Routes />
          </Layout>
        </BrowserRouter>
      </ConfigProvider>
    </UserProvider>
  ) : (
    <Login />
  );
}

export default App;
