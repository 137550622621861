import React from 'react';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NavLink } from 'react-router-dom';
import AddRecordButton from '../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../hooks/useCollection';
import { Form } from '../../../components/Form';
import { updateOrCreate } from '../../../components/Form/helpers/createOrUpdate';
import {
  EmployeeResume,
  EmployeeResumeType,
  collection,
} from '../../../models/EmployeeResume';
import useQuery from '../../../hooks/useQuery';
import { ProgressSteps } from '../components/ProgressSteps/ProgressSteps';
import { NextStepBar } from '../components/NextStepBar/NextStepBar';
import { duplicateResume } from '../../../helpers/duplicateResume';
import { deleteResume } from '../../../helpers/deleteResume';
import DuplicateRecordButton from '../../../components/DuplicateRecordButton/DuplicateRecordButton';
import useSession from '../../../hooks/useSession';
import { UserConsumer } from '../../../context/userContext';
import { DownloadOutlined } from '@ant-design/icons';

const columns: ColumnsType<EmployeeResumeType> = [
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name!.localeCompare(b.name!),
    render: (name, record) => {
      return (
        <NavLink to={`/cv/werknemer/${record.employee_id}/cvs/${record.id}`}>
          {name}
        </NavLink>
      );
    },
  },
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id, data) => {
      return (
        <UserConsumer>
          {({ user }) => (
            <Space>
              <DuplicateRecordButton
                duplicateFunction={() => duplicateResume(id, user?.id)}
              />
              <NavLink
                to={`/cv/werknemer/${data.employee_id}/cvs/${id}/preview/pdf/download`}
              >
                <Button shape="circle">
                  <DownloadOutlined />
                </Button>
              </NavLink>
              <DeleteButton
                id={id}
                collection={collection}
                canDelete={data.created_by === user?.id}
                deleteFunction={() => deleteResume(id)}
              />
            </Space>
          )}
        </UserConsumer>
      );
    },
  },
];

export type EmployeeResumesProps = {
  employeeId: string;
};

const EmployeeResumes: React.ComponentType<EmployeeResumesProps> = ({
  employeeId,
}) => {
  const user = useSession();
  const query = useQuery(EmployeeResume).where('employee_id', '==', employeeId);
  const [items] = useCollection(EmployeeResume, query);

  return (
    <Space
      style={{ height: '100%', width: '100%', overflow: 'scroll', padding: 24 }}
      direction="vertical"
    >
      <ProgressSteps current={2} />
      <NextStepBar previousHref={`/cv/werknemer/${employeeId}`} />
      <AddRecordButton
        model={EmployeeResume}
        value="Nieuw CV"
        initialValues={{ employee_id: employeeId, created_by: user?.id }}
      />
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(EmployeeResume, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={EmployeeResume.schema}
            schemaDescription={EmployeeResume.schemaDescription}
            getFormProperties={() => EmployeeResume.formProperties}
          />
        )}
      />
    </Space>
  );
};

export default EmployeeResumes;
