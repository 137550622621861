export type MultiLingualValueType = {
  value?: string;
  language: string;
};

export const getValueInLanguage = (
  value: MultiLingualValueType[] | undefined | any,
  language: string
): any | undefined => {
  return (
    value &&
    value.find(
      (item: MultiLingualValueType | any) => item.language === language
    )?.value
  );
};

export const getValueInLanguageSafe = (
  value: MultiLingualValueType[] | undefined | any,
  language: string
): string => {
  return getValueInLanguage(value, language) || '';
};

export const getValueInCurrentLanguage = (
  value: MultiLingualValueType[] | undefined
): string => getValueInLanguageSafe(value, 'nl');
