/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { auth, googleAuthProvider, firebaseApp } from '../services/firebase';
import { UserType } from '../models/User';

type AuthState = {
  user: UserType | null;
  initializing: boolean;
  authorized: boolean;
};

export const useAuth = () => {
  // const currentUser = auth.currentUser;
  const [state, setState] = useState<AuthState>({
    initializing: false,
    user: null,
    authorized: false,
  });

  function onAuthStateChanged(user: firebase.User | null) {
    if (user) {
      setState({
        ...state,
        initializing: true,
      });

      firebaseApp
        .firestore()
        .collection('users')
        .doc(user.uid)
        .onSnapshot(
          (fsUserDoc) => {
            const data = fsUserDoc.data() as UserType;

            setState({
              ...state,
              user: data || null,
              initializing: false,
              authorized: Boolean(fsUserDoc && data),
            });
          },
          (error) => {
            setState({
              ...state,
              user: null,
              initializing: false,
              authorized: false,
            });
            console.log('error getting user doc', error);
          }
        );
    } else {
      setState({ ...state, user: null, authorized: false });
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(onAuthStateChanged);
    return () => unsubscribe();
  }, []);

  return state;
};

export const login = async () => {
  try {
    const result = await auth.signInWithPopup(googleAuthProvider);
    console.log(result);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const signOut = () => auth.signOut();
