import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setGlobalDateI18n } from 'fecha';
import { locale } from './locale';

export async function setupI18n() {
  await i18n.use(initReactI18next).init({
    resources: locale,
    lng: 'nl',
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false,
    },
  });

  setGlobalDateI18n(i18n.t('dates', { returnObjects: true }));
}
