import React, { useEffect, useState } from 'react';
import { CreateResumeAttributes, createResume } from './createdTemplatedFile';

export type DocxResumeProps = CreateResumeAttributes & {
  children: (blob?: Blob) => any;
};

export const DocxResume: React.ComponentType<DocxResumeProps> = (props) => {
  const { children } = props;
  const [blob, setBlob] = useState<Blob>();

  useEffect(() => {
    const createDocx = async () => {
      const blob = await createResume(props);
      setBlob(blob);
    };

    createDocx();
  }, [props]);

  return typeof children === 'function' && blob ? children(blob) || null : null;
};
