import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  connected: {
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eeeeee',
    marginLeft: 3,
  },
});
