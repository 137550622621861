import React from 'react';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import {
  updateOrCreate,
  createNew,
} from '../../../../components/Form/helpers/createOrUpdate';
import { EmployeeResumeGeneral } from '../../../../models/EmployeeResumeGeneral';
import useQuery from '../../../../hooks/useQuery';

export type EmployeeResumeGeneralFormProps = {
  resumeId?: string;
};

const EmployeeResumeGeneralForm: React.ComponentType<
  EmployeeResumeGeneralFormProps
> = ({ resumeId }) => {
  const query = useQuery(EmployeeResumeGeneral).where(
    'resume_id',
    '==',
    resumeId
  );
  const [items, isDone] = useCollection(EmployeeResumeGeneral, query);
  const [item] = items;

  return (
    <>
      {isDone && (
        <Form
          center={false}
          onSubmit={(updatedValue) => {
            updateOrCreate(EmployeeResumeGeneral, updatedValue);
          }}
          onCancel={() => {}}
          value={() => {
            return item
              ? item
              : { ...createNew(EmployeeResumeGeneral), resume_id: resumeId };
          }}
          schema={EmployeeResumeGeneral.schema}
          schemaDescription={EmployeeResumeGeneral.schemaDescription}
          getFormProperties={() => EmployeeResumeGeneral.formProperties}
        />
      )}
    </>
  );
};

export default EmployeeResumeGeneralForm;
