import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, Space } from 'antd';
import useCollection from '../../../hooks/useCollection';
import { Employee } from '../../../models/Employee';
import { ProgressSteps } from '../components/ProgressSteps/ProgressSteps';

const EmployeeSelection = () => {
  const [items] = useCollection(Employee);

  return (
    <Space
      style={{ height: '100%', width: '100%', overflow: 'scroll', padding: 24 }}
      direction="vertical"
    >
      <ProgressSteps current={0} />
      <List
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <NavLink
                  to={`/cv/werknemer/${item.id}`}
                >{`${item.firstName} ${item.lastName}`}</NavLink>
              }
            />
          </List.Item>
        )}
      />
    </Space>
  );
};

export default EmployeeSelection;
