import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './SectionHeader.styles';

export type SectionHeaderProps = {
  children: React.ReactNode;
};

export const SectionHeader: React.ComponentType<SectionHeaderProps> = ({
  children,
}) => (
  <View style={styles.wrapper}>
    <Text style={styles.title}>{children}</Text>
  </View>
);
