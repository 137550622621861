import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './KeywordList.styles';
import { List, ListItem } from '../List';

export type KeywordListProps = {
  keywords: string[];
  columnCount?: number;
};

export const KeywordList: React.ComponentType<KeywordListProps> = ({
  keywords,
  columnCount = 1,
}) => {
  const columns: string[][] = new Array(columnCount)
    .fill(null)
    .map((_, index) => index)
    .reduce<string[][]>((acc, columnIndex) => {
      const minimalCount = Math.floor(keywords.length / columnCount);
      const remaining = Math.ceil(
        ((keywords.length % columnCount) - columnIndex) / columnCount
      );
      const itemCount = minimalCount + remaining;
      const startIndex = acc[columnIndex - 1]
        ? acc.reduce(
            (acc, current, index) =>
              index < columnIndex ? acc + current.length : acc,
            0
          )
        : 0;
      const itemLength = itemCount;

      return [...acc, [...keywords].splice(startIndex, itemLength)];
    }, []);

  return (
    <View style={styles.listsWrapper}>
      {columns.map((column, index) => (
        <List
          key={`column${index}`}
          style={{ width: `${Math.floor(100 / columnCount)}%` }}
        >
          {column.map((keyword) => (
            <ListItem key={keyword}>
              <Text style={styles.listItem}>{keyword}</Text>
            </ListItem>
          ))}
        </List>
      ))}
    </View>
  );
};
