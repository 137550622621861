import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Resume } from './pages/Resume';
import { Data } from './pages/Data/Data';
import Employees from './pages/Employee/Employee';
import Users from './pages/User/User';
import useSession from './hooks/useSession';
import { DrafResume } from './pages/DraftResume/DraftResume';

export const Routes: React.ComponentType<{}> = () => {
  const user = useSession();

  return (
    <Switch>
      <Route path="/cv" component={Resume} />
      {user && user.admin && (
        <>
          <Route path="/data" component={Data} />
          <Route path="/users" component={Users} />
          <Route path="/employees" component={Employees} />
          <Route path="/draft-resume" component={DrafResume} />
        </>
      )}
    </Switch>
  );
};
