import React from 'react';
import { Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProgressSteps } from '../components/ProgressSteps/ProgressSteps';
import EmployeeEducations from './EmployeeEducations/EmployeeEducations';
import { NextStepBar } from '../components/NextStepBar/NextStepBar';
import EmployeeLanguages from './EmployeeLanguages/EmployeeLanguages';
import EmployeeExtra from './EmployeeExtra/EmployeeExtra';

export type EmployeeDataProps = {
  employeeId: string;
};

export const EmployeeData: React.ComponentType<EmployeeDataProps> = ({
  employeeId,
}) => {
  const [t] = useTranslation();

  return (
    <Space
      style={{ height: '100%', width: '100%', overflow: 'scroll', padding: 24 }}
      direction="vertical"
    >
      <ProgressSteps current={1} />
      <NextStepBar
        previousHref="/cv"
        nextHref={`/cv/werknemer/${employeeId}/cvs`}
      />
      <Tabs defaultActiveKey="1" tabPosition="top" type="line" size="large">
        <Tabs.TabPane tab={t('employeeData.sections.languages.title')} key="1">
          <EmployeeLanguages employeeId={employeeId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('employeeData.sections.education.title')} key="2">
          <EmployeeEducations employeeId={employeeId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('employeeData.sections.extra.title')} key="3">
          <EmployeeExtra employeeId={employeeId} />
        </Tabs.TabPane>
      </Tabs>
    </Space>
  );
};

export default EmployeeData;
