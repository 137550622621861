import React from 'react';
import { View } from '@react-pdf/renderer';
import { styles } from './ListeItem.styles';

export type ListItemProps = {
  type?: 'primary' | 'secondary';
  lineFontSize?: number;
  style?: any;
  connect?: boolean;
  children: React.ReactNode;
};

export const ListItem: React.ComponentType<ListItemProps> = ({
  type = 'primary',
  lineFontSize = 10,
  style = {},
  connect = false,
  children,
}) => {
  const bulletStyle = {
    marginTop: lineFontSize / 2 - 1,
    ...styles.bullet,
    ...(type === 'primary' ? styles.primaryBullet : styles.secondaryBullet),
  };

  return (
    <View
      style={{
        ...styles.wrapper,
        ...style,
        ...(connect ? styles.connected : {}),
      }}
    >
      <View style={bulletStyle}></View>
      <View style={styles.content}>{children}</View>
    </View>
  );
};
