import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, Button, Tag } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { SkillType } from '../../models/Skill';
import classes from './SkillList.module.css';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { useSelectedSkills } from '../../hooks/useSelectedSkills';

export type SkillListType = { id: string; refId?: string };

export type SkillListProps = {
  compact?: boolean;
  skills: SkillListType[];
  onDelete?: (id: string) => void;
  onOrderChange: (order: SkillType[]) => void;
};

export const SkillList: React.ComponentType<SkillListProps> = ({
  skills,
  onDelete,
  onOrderChange: handleOrderChange,
  compact = false,
}) => {
  const [t] = useTranslation();
  const [selectedSkills, isDone] = useSelectedSkills(skills);

  if (!isDone) {
    return null;
  }

  async function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (!selectedSkills) {
      return undefined;
    }

    const [removed] = selectedSkills.splice(result.source.index, 1);
    selectedSkills.splice(result.destination.index, 0, removed);

    handleOrderChange(selectedSkills);
  }

  return compact ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable key={'compact'} droppableId={'compact-all'}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {selectedSkills?.map((item, index) => (
              <Draggable
                draggableId={`compact-${item.id}`}
                index={index}
                key={item.id}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Tag
                      key={item.id}
                      closable={Boolean(onDelete)}
                      onClose={() =>
                        onDelete && onDelete(item?.refId || item.id)
                      }
                    >
                      {item.name}
                    </Tag>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.wrapper}>
        <Droppable key={'long'} droppableId={`long-all`}>
          {(provided) => (
            <div
              className={classes.list}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <List
                itemLayout="horizontal"
                dataSource={selectedSkills}
                header={<h3>{t('resume.sections.skills.title')}</h3>}
                renderItem={(item, index) => (
                  <Draggable draggableId={`all-${item.id}`} index={index} key={item.id}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <List.Item
                          actions={
                            onDelete
                              ? [
                                  <Button
                                    danger
                                    shape="circle"
                                    onClick={() =>
                                      onDelete(item?.refId || item.id)
                                    }
                                  >
                                    <DeleteOutlined />
                                  </Button>,
                                ]
                              : []
                          }
                        >
                          <List.Item.Meta title={item.name} />
                        </List.Item>
                      </div>
                    )}
                  </Draggable>
                )}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default SkillList;
