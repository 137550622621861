import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';
import { getValueInCurrentLanguage } from '../helpers/getValueInLanguage';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  employee_id: yup.string().required().label('Medewerker ID'),
  note: multilingualSchema(yup.string()).label('Notitie'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
    hidden: true,
  },
  employee_id: {
    readonly: true,
    hidden: true,
  },
  note: {
    textarea: true,
  },
};

export const collection = 'employee_extra';

export const EmployeeResumeExtra: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => getValueInCurrentLanguage(data.note),
};

export type EmployeeExtraType = RecordType;
