import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    width: 142,
    backgroundColor: '#f8f8f8',
    borderColor: '#eeeeee',
    borderStyle: 'solid',
    borderRightWidth: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    fontFamily: 'Inter',
    fontSize: 11,
    fontWeight: 300,
    color: '#1e1e1e',
    paddingTop: 190,
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 50,
  },
  infoPair: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: 10,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 18,
  },
  label: {
    fontWeight: 500,
    paddingBottom: 2,
  },
});
