import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { getEmployee, getResume } from '../../../../helpers/genericGetters';

const { Step } = Steps;

export type ProgressStepsProps = {
  current: number;
};

export const ProgressSteps: React.ComponentType<ProgressStepsProps> = ({
  current,
}) => {
  const [employee, setEmployee] = useState<null | string>(null);
  const [resume, setResume] = useState<null | string>(null);
  const [t] = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      const [, , , employeeId, , resumeId] =
        window.location.pathname.split('/');

      if (employeeId) {
        const employeeData = await getEmployee(employeeId);
        setEmployee(`${employeeData.firstName} ${employeeData.lastName}`);
      }

      if (resumeId) {
        const resumeData = await getResume(resumeId);
        setResume(resumeData.name!);
      }
    };

    loadData();
  }, [current]);

  return (
    <div style={{ paddingBottom: 24 }}>
      <Steps current={current}>
        <Step
          title={t('progressSteps.step1.title')}
          description={employee || t('progressSteps.step1.description')}
        />
        <Step
          title={t('progressSteps.step2.title')}
          description={t('progressSteps.step2.description')}
        />
        <Step
          title={t('progressSteps.step3.title')}
          description={resume || t('progressSteps.step3.description')}
        />
        <Step
          title={t('progressSteps.step4.title')}
          description={t('progressSteps.step4.description')}
        />
        <Step
          title={t('progressSteps.step5.title')}
          description={t('progressSteps.step5.description')}
        />
      </Steps>
    </div>
  );
};
