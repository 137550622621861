import React from 'react';
import { Text } from '@react-pdf/renderer';
import { styles } from './TimeSpan.styles';

export type TimeSpanProps = {
  start: string;
  end: string;
};

export const TimeSpan: React.ComponentType<TimeSpanProps> = ({
  start,
  end,
}) => {
  return (
    <Text style={styles.wrapper}>
      {start} - {end}
    </Text>
  );
};
