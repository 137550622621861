import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  authorized: yup.boolean().notRequired().label('Heeft toegang?'),
  admin: yup.boolean().required().label('Admin?'),
  employeeId: yup.string().label('Werknemer ID'),
  displayName: yup.string().required().label('Naam'),
  email: yup.string().email().required().label('E-mail'),
  photoURL: yup.string().url().required().label('Foto URL'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  authorized: {
    readonly: true,
  },
  displayName: {
    readonly: true,
  },
  email: {
    readonly: true,
  },
  photoURL: {
    readonly: true,
  },
};

export const collection = 'users';

export const User: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.displayName,
};

export type UserType = RecordType;
