import React from 'react';
import { View } from '@react-pdf/renderer';
import { styles } from './List.styles';

export type ListProps = {
  style?: any;
  connect?: boolean;
  children: React.ReactNode;
};

export const List: React.ComponentType<ListProps> = ({
  style = {},
  children,
  connect,
}) => {
  return (
    <View
      style={{
        ...styles.wrapper,
        ...style,
        ...(connect ? styles.connected : {}),
      }}
    >
      {children}
    </View>
  );
};
