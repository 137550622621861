import React, { useEffect, useState } from 'react';
import useQuery from '../../../../hooks/useQuery';
import useCollection from '../../../../hooks/useCollection';
import {
  EmployeeResumeJob,
  EmployeeResumeJobType,
} from '../../../../models/EmployeeResumeJob';
import { ResumeOrderJob } from './ResumeOrderJob';
import { SortableList } from '../../../../components/SortableList/SortableList';
import { ResumeOrderProjectList } from '../ResumeOrderProjectList/ResumeOrderProjectList';
import { sortJob } from '../../../../helpers/genericSorters';
import { firebaseApp } from '../../../../services/firebase';

export type ResumeOrderJobListProps = {
  resumeId: string;
  onUpdate: () => void;
};

export const ResumeOrderJobList: React.ComponentType<
  ResumeOrderJobListProps
> = ({ resumeId, onUpdate }) => {
  const query = useQuery(EmployeeResumeJob).where('resume_id', '==', resumeId);
  const [items] = useCollection(EmployeeResumeJob, query);

  const [currentItems, setCurrentItems] = useState<EmployeeResumeJobType[]>([]);

  async function handleOrderChange(items: EmployeeResumeJobType[]) {
    const newItems = items.map((item, index) => ({ ...item, order: index }));
    setCurrentItems(newItems);

    await Promise.all(
      // eslint-disable-next-line array-callback-return
      newItems.map((item, index) => {
        const doc = firebaseApp
          .firestore()
          .collection(EmployeeResumeJob.collection)
          .doc(item.id);

        doc.update({
          ...item,
          order: index,
        });
      })
    );

    onUpdate();
  }

  useEffect(() => {
    if (items.length) {
      setCurrentItems(items);
    }
  }, [items]);

  return (
    items && (
      <SortableList<EmployeeResumeJobType>
        items={currentItems.sort(sortJob)}
        renderItem={(item) => (
          <ResumeOrderJob
            job={item as EmployeeResumeJobType}
            style={{ marginBottom: 10 }}
          >
            <ResumeOrderProjectList onUpdate={onUpdate} jobId={item.id} />
          </ResumeOrderJob>
        )}
        onOrderUpdate={handleOrderChange}
      />
    )
  );
};
