import React from 'react';
import { List, Checkbox } from 'antd';
import useCollection from '../../../../hooks/useCollection';
import {
  updateOrCreate,
  createNew,
} from '../../../../components/Form/helpers/createOrUpdate';
import useQuery from '../../../../hooks/useQuery';
import {
  Characteristic,
  CharacteristicType,
} from '../../../../models/Characteristic';
import { EmployeeResumeCharacteristic } from '../../../../models/EmployeeResumeCharacteristic';
import { getValueInCurrentLanguage } from '../../../../helpers/getValueInLanguage';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { firebaseApp } from '../../../../services/firebase';

export type ResumeCharacteristicsProps = {
  resumeId: string;
};

const ResumeCharacteristics: React.ComponentType<
  ResumeCharacteristicsProps
> = ({ resumeId }) => {
  const [allCharacteristics] = useCollection(Characteristic);
  const sortedCharacteristics = allCharacteristics.sort((a, b) => {
    return a.order - b.order;
  });

  const query = useQuery(EmployeeResumeCharacteristic).where(
    'resume_id',
    '==',
    resumeId
  );
  const [[characteristics]] = useCollection(
    EmployeeResumeCharacteristic,
    query
  );
  const characteristicsIds = characteristics?.characteristic_id as string[] ?? [];

  const handleCharacteristicUpdate = (newCharacteristic: string[]) => {
    const record = characteristics
      ? { ...characteristics, characteristic_id: newCharacteristic }
      : {
          ...createNew(EmployeeResumeCharacteristic),
          resume_id: resumeId,
          characteristic_id: newCharacteristic,
        };

    updateOrCreate(EmployeeResumeCharacteristic, record);
  };

  const hasCharacteristic = (id: string) => characteristicsIds?.includes(id);

  const handleCharacteristicSelect = (item: CharacteristicType) => {
    handleCharacteristicUpdate([...characteristicsIds, item.id]);
  };

  const handleRemoveCharacteristic = (id: string) => {
    handleCharacteristicUpdate(
      characteristicsIds.filter((characteristicId) => characteristicId !== id)
    );
  };

  async function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    const reOrderedcharacteristics = [...sortedCharacteristics];

    const [removed] = reOrderedcharacteristics.splice(result.source.index, 1);
    reOrderedcharacteristics.splice(result.destination.index, 0, removed);

    await Promise.all(
      // eslint-disable-next-line array-callback-return
      reOrderedcharacteristics.map((item, index) => {
        const doc = firebaseApp
          .firestore()
          .collection(Characteristic.collection)
          .doc(item.id);

        doc.update({
          ...item,
          order: index,
        });
      })
    );
  }

  return (
    /*#
      Todo:  
        Drag and drops works with just 1 long list for now, but not with multiple lists. 
        Since the styling has a grid feature, you actually want to have multiple lists.

        1: We could dived the characteristics into 3 lists, and then have 3 droppables (For the UI, you could have 1 list with 2 items and another with 18). 
        Or pherhaps use an other lib, a good contender could be dnd-kit.
    */
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable`}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <List
              dataSource={sortedCharacteristics}
              renderItem={(item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <List.Item>
                        <Checkbox
                          checked={hasCharacteristic(item.id)}
                          onChange={(value) => {
                            value.target.checked
                              ? handleCharacteristicSelect(item)
                              : handleRemoveCharacteristic(item.id);
                          }}
                        >
                          {getValueInCurrentLanguage(item.label)}
                        </Checkbox>
                      </List.Item>
                    </div>
                  )}
                </Draggable>
              )}
            ></List>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ResumeCharacteristics;
