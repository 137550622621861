import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'fecha';
import { TimeSpan } from '../../../../TimeSpan/TimeSpan';

export type ExperienceTimeSpanProps = {
  startDate: string;
  endDate?: string;
};

export const ExperienceTimeSpan: React.ComponentType<
  ExperienceTimeSpanProps
> = ({ startDate, endDate }) => {
  const [t] = useTranslation();
  const formattedStartDate = format(
    new Date(startDate),
    t('resume.sections.jobs.dateFormat')!
  );
  const formattedEndDate = endDate
    ? format(new Date(endDate), t('resume.sections.jobs.dateFormat')!)
    : t('resume.sections.jobs.present');

  return <TimeSpan start={formattedStartDate} end={formattedEndDate} />;
};
