import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../hooks/useCollection';
import { Form } from '../../../components/Form';
import { updateOrCreate } from '../../../components/Form/helpers/createOrUpdate';
import {
  Characteristic,
  CharacteristicType,
  collection,
} from '../../../models/Characteristic';
import { multiLingualColumn } from '../../../components/CollectionTable/helpers/multiLingualColumn';
import { Space } from 'antd';

const columns: ColumnsType<CharacteristicType> = [
  multiLingualColumn<CharacteristicType>(
    {
      title: 'Kenmerk',
      dataIndex: 'label',
      key: 'label',
    },
    'label'
  ),
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={collection} />,
  },
];

const Characteristics = () => {
  const [items] = useCollection(Characteristic);

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        height: 'calc(100vh - 170px)',
        overflow: 'scroll',
      }}
    >
      <AddRecordButton model={Characteristic} value="Nieuw kenmerk" />
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(Characteristic, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={Characteristic.schema}
            schemaDescription={Characteristic.schemaDescription}
            getFormProperties={() => Characteristic.formProperties}
          />
        )}
      />
    </Space>
  );
};

export default Characteristics;
