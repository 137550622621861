import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import EmployeeSelection from './EmployeeSelection/EmployeeSelection';
import EmployeeResumes from './ResumeSelection/ResumeSelection';
import { ResumeData } from './ResumeData/ResumeData';
import EmployeeData from './EmployeeData/EmployeeData';
import { ResumePreview } from './ResumePreview/ResumePreview';
import useSession from '../../hooks/useSession';

export const Resume: React.ComponentType<{}> = () => {
  const user = useSession();

  return (
    <Switch>
      {user?.admin ? (
        <Route path="/cv" exact component={EmployeeSelection} />
      ) : (
        <Redirect path="/cv" exact to={`/cv/werknemer/${user?.employeeId}`} />
      )}
      <Route
        path="/cv/werknemer/:id"
        exact
        render={({ match: { params } }) => (
          <EmployeeData employeeId={params.id} />
        )}
      />
      <Route
        path="/cv/werknemer/:id/cvs"
        exact
        render={({ match: { params } }) => (
          <EmployeeResumes employeeId={params.id} />
        )}
      />
      <Route
        path="/cv/werknemer/:id/cvs/:resumeId"
        exact
        render={({ match: { params } }) => (
          <ResumeData employeeId={params.id} resumeId={params.resumeId} />
        )}
      />
      <Route
        path="/cv/werknemer/:id/cvs/:resumeId/preview"
        render={({ match: { params } }) => (
          <ResumePreview employeeId={params.id} resumeId={params.resumeId} />
        )}
      />
    </Switch>
  );
};
