import { firebaseApp } from '../services/firebase';
import {
  getResumeQuery,
  getBasicEmployeeSkillsQuery,
  getResumeFineTuningQuery,
  getResumeCharacteristicsQuery,
  getResumeGeneralQuery,
  getResumeJobsQuery,
  getResumeProjectsQuery,
} from './genericGetters';

export const deleteResume = async (resumeId: string) => {
  const queries = [
    getResumeQuery(resumeId),
    getBasicEmployeeSkillsQuery(resumeId),
    getResumeFineTuningQuery(resumeId),
    getResumeCharacteristicsQuery(resumeId),
    getResumeGeneralQuery(resumeId),
    getResumeJobsQuery(resumeId),
    getResumeProjectsQuery(resumeId),
  ];

  const batch = firebaseApp.firestore().batch();

  for (let query of queries) {
    const snapshot = await query.get();
    snapshot.forEach((item: any) => batch.delete(item.ref));
  }

  return batch.commit();
};
