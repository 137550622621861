import React from 'react';
import { Canvas } from '@react-pdf/renderer';

export const Background: React.ComponentType<{ style?: any }> = ({ style }) => (
  <Canvas
    style={style}
    paint={(painter, availableWidth, availableHeight): null => {
      painter.fillColor('#13ca91');
      painter.moveTo(0, 0);
      painter.polygon(
        [0, 0],
        [availableWidth, 0],
        [availableWidth, availableHeight - 20],
        [0, availableHeight]
      );
      painter.fill();
      return null;
    }}
  />
);
