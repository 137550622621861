import React from 'react';
import { Select } from 'antd';
import { FieldProps } from '../../../../../components/Form/fields';
import { EmployeeResumeProjectType } from '../../../../../models/EmployeeResumeProject';
import useQuery from '../../../../../hooks/useQuery';
import { EmployeeResumeJob } from '../../../../../models/EmployeeResumeJob';
import useCollection from '../../../../../hooks/useCollection';

export const JobField = ({
  value,
  onChange,
  readonly,
  errors,
  rootValue,
}: FieldProps<string, EmployeeResumeProjectType>) => {
  const query = useQuery(EmployeeResumeJob).where(
    'resume_id',
    '==',
    rootValue?.resume_id
  );
  const [items] = useCollection(EmployeeResumeJob, query);
  const options = items.map((job) => ({
    value: job.id,
    label: job.company,
  }));

  const fieldErrors = errors.filter((err) => err.path?.match(/^[a-zA-Z]*$/));

  return (
    <>
      <Select
        style={{ width: '100%' }}
        disabled={readonly}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <Select.Option value={option.value} key={option.value}>
            <span>{option.label}</span>
          </Select.Option>
        ))}
      </Select>
      {!readonly && fieldErrors.length > 0 && (
        <div className="form--error">
          {fieldErrors.map((err) => err.message).join('. ')}
        </div>
      )}
    </>
  );
};

export default JobField;
