import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 142,
    // paddingLeft: 175,
    right: 0,
    bottom: 0,
    height: 50,
    // backgroundColor: 'white',
    flexWrap: 'wrap',
    // color: 'white',
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: 8,
  },
});
