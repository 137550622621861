export const locale = {
  nl: {
    translation: {
      dates: {
        dayNamesShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        dayNames: [
          'Zondag',
          'Maandag',
          'Dinsdag',
          'Woensdag',
          'Donderdag',
          'Vrijdag',
          'Zaterdag',
        ],
        monthNamesShort: [
          'jan',
          'feb',
          'mrt',
          'apr',
          'mei',
          'jun',
          'jul',
          'aug',
          'sep',
          'okt',
          'nov',
          'dec',
        ],
        monthNames: [
          'januari',
          'februari',
          'maart',
          'april',
          'mei',
          'juni',
          'juli',
          'augustus',
          'september',
          'oktober',
          'november',
          'december',
        ],
        DoFn: (month: string): string => month,
        dateFormatText: 'DD MMMM YYYY',
        dateFormatTextShort: 'D MMM YYYY',
        dateFormat: 'DD-MM-YYYY',
        timeFormat: 'HH:mm',
        timeFormatFull: 'HH:mm:ss',
      },
      resume: {
        person: {
          name: 'Naam',
          jobTitle: 'Functie',
          dateOfBirth: 'Geboortedatum',
          dateFormat: 'D MMMM YYYY',
          cityOfResidence: 'Woonplaats',
          nationality: 'Nationaliteit',
          spokenLanguages: 'Talen',
          attributes: 'Kenmerken',
        },
        nationality: {
          dutch: 'Nederlandse',
        },
        languageLevels: {
          native: 'moedertaal',
          fluent: 'vloeiend',
          proficient: 'bekwaam',
          intermediate: 'beginner',
        },
        sections: {
          intro: {
            title: 'Introductie',
          },
          quotes: {
            title: 'Referentie',
          },
          characteristics: {
            title: 'Kenmerken',
          },
          skills: {
            title: 'Vaardigheden',
            types: {
              language: 'Talen',
              framework: 'Frameworks',
              tool: 'Tools',
              'ci/cd': 'CI/CD',
              software: 'Software',
              other: 'Overig',
              api: 'API',
              database: 'Database',
            },
          },
          experience: {
            title: 'Werkervaring',
          },
          jobs: {
            title: 'Werkervaring',
            dateFormat: 'MMMM YYYY',
            present: 'huidig',
            techStack: 'Tech stack',
            preview: 'URL',
            repo: 'Repo',
          },
          education: {
            title: 'Opleiding',
            profile: 'Profiel',
            graduated: 'Geslaagd',
          },
        },
      },
      fileField: {
        drag: 'Sleep je bestand hierheen',
        drop: 'Drop je bestand hier',
        or: 'of',
        select: 'Selecteer een bestand',
      },
      downloadBar: {
        chooseLanguage: 'Downloaden in taal:',
        anonymouseData: 'met persoonsgegevens:',
        downloadFile: 'als formaat:',
      },
      progressSteps: {
        step1: { title: 'Werknemer', description: 'Kies een werknemer' },
        step2: {
          title: 'Gegevens',
          description: 'Algemene werknemer gegevens',
        },
        step3: { title: 'CV', description: 'Kies of maak een nieuw CV' },
        step4: { title: 'Gegevens', description: 'Vul CV gegevens in' },
        step5: { title: 'Preview', description: 'Preview & tweak' },
      },
      employeeData: {
        sections: {
          languages: { title: 'Talen' },
          education: { title: 'Opleiding' },
          extra: { title: 'Extra' },
        },
      },
      resumeData: {
        sections: {
          general: { title: 'Algemeen' },
          skills: { title: 'Skills', addedSkills: 'Toegevoegde skills' },
          characteristics: { title: 'Kenmerken' },
          jobs: { title: 'Banen' },
          projects: { title: 'Projecten' },
          quotes: { title: 'Quotes' },
        },
      },
      resumePreview: {
        sections: {
          sections: { title: 'Secties' },
          order: { title: 'Volgorde' },
        },
      },
      skillAutoComplete: {
        placeholder: 'Zoek een skill...',
      },
      navigation: {
        next: 'Volgende',
        previous: 'Vorige',
      },
      form: {
        create: 'Toevoegen',
        update: 'Bijwerken',
        delete: 'Verwijderen',
        cancel: 'Annuleren',
      },
      models: {
        employee: {
          id: 'ID',
          name: 'Naam',
          number: 'Werknemernummer',
        },
        user: {
          id: 'ID',
          admin: 'Admin?',
          displayName: 'Naam',
          email: 'E-mail',
          photoURL: 'Foto URL',
        },
      },
    },
  },
  en: {
    translation: {
      dates: {
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNames: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saterday',
        ],
        monthNamesShort: [
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec',
        ],
        monthNames: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        DoFn: (month: string): string => month,
        dateFormatText: 'DD MMMM YYYY',
        dateFormatTextShort: 'D MMM YYYY',
        dateFormat: 'DD-MM-YYYY',
        timeFormat: 'HH:mm',
        timeFormatFull: 'HH:mm:ss',
      },
      resume: {
        person: {
          name: 'Name',
          jobTitle: 'Job title',
          dateOfBirth: 'Date of birth',
          dateFormat: 'D MMMM YYYY',
          cityOfResidence: 'City of residence',
          nationality: 'Nationality',
          spokenLanguages: 'Languages',
          attributes: 'Characteristics',
        },
        nationality: {
          dutch: 'Dutch',
        },
        languageLevels: {
          native: 'native',
          fluent: 'fluent',
          proficient: 'proficient',
          intermediate: 'intermediate',
        },
        sections: {
          intro: {
            title: 'Introduction',
          },
          quotes: {
            title: 'Referral',
          },
          characteristics: {
            title: 'Characteristics',
          },
          skills: {
            title: 'Skills',
            types: {
              language: 'Languages',
              framework: 'Frameworks',
              tool: 'Tools',
              'ci/cd': 'CI/CD',
              software: 'Software',
              other: 'Other',
              api: 'API',
              database: 'Database',
            },
          },
          experience: {
            title: 'Experience',
          },
          jobs: {
            title: 'Experience',
            dateFormat: 'MMMM YYYY',
            present: 'present',
            techStack: 'Tech stack',
            preview: 'URL',
            repo: 'Repo',
          },
          education: {
            title: 'Education',
            profile: 'Profile',
            graduated: 'Graduated',
          },
        },
      },
    },
  },
};
