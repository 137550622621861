import React, { useEffect, useState } from 'react';
import useQuery from '../../../../hooks/useQuery';
import {
  EmployeeResumeProject,
  EmployeeResumeProjectType,
} from '../../../../models/EmployeeResumeProject';
import useCollection from '../../../../hooks/useCollection';
import { SortableList } from '../../../../components/SortableList/SortableList';
import { ResumeOrderProject } from './ResumeOrderProject';
import { sortProject } from '../../../../helpers/genericSorters';
import { firebaseApp } from '../../../../services/firebase';

export type ResumeOrderProjectListProps = {
  jobId: string | number;
  onUpdate: () => void;
  [key: string]: any;
};

export const ResumeOrderProjectList: React.ComponentType<
  ResumeOrderProjectListProps
> = ({ jobId, onUpdate }) => {
  const query = useQuery(EmployeeResumeProject).where('job_id', '==', jobId);
  const [items] = useCollection(EmployeeResumeProject, query);
  const [currentItems, setCurrentItems] = useState<EmployeeResumeProjectType[]>(
    []
  );

  async function handleOrderChange(items: EmployeeResumeProjectType[]) {
    const newItems = items.map((item, index) => ({ ...item, order: index }));
    setCurrentItems(newItems);

    await Promise.all(
      // eslint-disable-next-line array-callback-return
      newItems.map((item, index) => {
        const doc = firebaseApp
          .firestore()
          .collection(EmployeeResumeProject.collection)
          .doc(item.id);

        doc.update({
          ...item,
          order: index,
        });
      })
    );

    onUpdate();
  }

  useEffect(() => {
    if (items.length) {
      setCurrentItems(items);
    }
  }, [items]);

  return (
    items && (
      <SortableList<EmployeeResumeProjectType>
        items={currentItems.sort(sortProject)}
        renderItem={(item) => (
          <ResumeOrderProject
            project={item as EmployeeResumeProjectType}
            style={{ marginBottom: 10 }}
          />
        )}
        onOrderUpdate={handleOrderChange}
      />
    )
  );
};
