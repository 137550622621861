import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skills as SkillsType } from '../../../../types/Resume.types';
import { Section, SectionProps } from '../../Section/Section';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { KeywordList } from '../../KeywordList/KeywordList';

export type SkillsProps = {
  skills: SkillsType;
} & Pick<SectionProps, 'offset'>;

export const Skills: React.ComponentType<SkillsProps> = ({
  skills,
  offset,
}) => {
  const [t] = useTranslation();

  return (
    <Section offset={offset}>
      <SectionHeader>{t('resume.sections.skills.title')}</SectionHeader>
      <KeywordList keywords={skills} columnCount={3} />
    </Section>
  );
};
