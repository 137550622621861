import React from 'react';
import { View, Text, Link } from '@react-pdf/renderer';
import { styles } from './Footer.styles';

type FooterProps = {
  children?: React.ReactNode;
};

export const Footer: React.ComponentType<FooterProps> = ({ children }) => {
  return (
    <View style={styles.wrapper} fixed={true}>
      <Text>[code]capi</Text>
      <Text>&nbsp;-&nbsp;</Text>
      <Text>Hollandse Kade 24, 1391 JD, Abcoude</Text>
      <Text>&nbsp;-&nbsp;</Text>
      <Link src="mailto:work@codecapi.nl">work@codecapi.nl</Link>
      <Text>&nbsp;-&nbsp;</Text>
      <Text>+31(0) 20 210 1299</Text>
    </View>
  );
};
