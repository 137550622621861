export const SKILL_TYPES = [
  'language',
  'framework',
  'tool',
  'ci/cd',
  'database',
  'software',
  'api',
  'other',
];
