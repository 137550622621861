import React from 'react';
import { firestore } from 'firebase';
import { Select, Spin } from 'antd';
import Models from '../../../models';
import { firebaseApp } from '../../../services/firebase';
import { FieldProps } from '.';

const ReferenceField = ({
  value,
  schemaDescription,
  onChange,
  readonly,
}: FieldProps<{ id: string }>) => {
  const [options, setOptions] = React.useState<
    null | firestore.QueryDocumentSnapshot<any>[]
  >(null);

  React.useEffect(() => {
    (async () => {
      const result = await firebaseApp
        .firestore()
        .collection(referedType.collection)
        .get();
      setOptions(result.docs);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!('meta' in schemaDescription) || !('model' in schemaDescription.meta!)) {
    return null;
  }
  const referedType =
    Models[(schemaDescription.meta as any).model as keyof typeof Models];

  if (!referedType) {
    return null;
  }

  if (!options) {
    return <Spin />;
  }

  const change = (selectedValue: string | null) => {
    if (selectedValue) {
      onChange(
        firebaseApp
          .firestore()
          .collection(referedType.collection)
          .doc(selectedValue)
      );
    } else {
      onChange({ id: null as any });
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={readonly}
      value={value?.id || (null as any)}
      onChange={change as any}
    >
      <Select.Option value={null as any} key={null as any}>
        <span>Kies...</span>
      </Select.Option>
      {options.map((option) => (
        <Select.Option value={option.ref.id} key={option.ref.id}>
          <span>{referedType.defaultDisplay(option.data())}</span>
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReferenceField;
