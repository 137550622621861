import React from 'react';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import { updateOrCreate } from '../../../../components/Form/helpers/createOrUpdate';
import useQuery from '../../../../hooks/useQuery';
import { EmployeeResumeExtra } from '../../../../models/EmployeeExtra';

export type EmployeeEducationsProps = {
  employeeId?: string;
};

const EmployeeExtra: React.ComponentType<EmployeeEducationsProps> = ({
  employeeId,
}) => {
  const query = useQuery(EmployeeResumeExtra).where(
    'employee_id',
    '==',
    employeeId
  );

  const [items] = useCollection(EmployeeResumeExtra, query);

  return (
    <Form
      onSubmit={(updatedValue) => {
        updateOrCreate(EmployeeResumeExtra, updatedValue);
      }}
      key={items.length}
      value={items[0] ?? { employee_id: employeeId }}
      schema={EmployeeResumeExtra.schema}
      schemaDescription={EmployeeResumeExtra.schemaDescription}
      getFormProperties={() => EmployeeResumeExtra.formProperties}
    />
  );
};

export default EmployeeExtra;
