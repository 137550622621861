import i18n from 'i18next';
import { format } from 'fecha';
import { ResumeData } from '../../../types/Resume.types';
import { DocxTemplateVariables } from '../DocxResume.types';
import { mapJobToTemplate } from './mapExperience';
import { flattenSkill } from './flattenSkill';

const splitArray = (arr: string[]): [string[], string[]] => {
  const left = [...arr].splice(0, Math.ceil(arr.length / 2));

  const right = [...arr].splice(left.length, Math.floor(arr.length / 2));

  return [left, right];
};

export function getTemplateVariables(
  resume: ResumeData
): DocxTemplateVariables {
  const { person, intro, skills, experience } = resume;
  //@ts-ignore
  const [skillsLeft, skillsRight] = splitArray(skills.map(flattenSkill));
  const [characteristicsLeft, characteristicsRight] = person.characteristics
    ? splitArray(person.characteristics)
    : [];

  return {
    personName: person.name!,
    personImageURL: person.imageURL!,
    personNameLabel: i18n.t('resume.person.name'),
    personCityOfResidence: person.cityOfResidence!,
    personCityOfResidenceLabel: i18n.t('resume.person.cityOfResidence'),
    personDateOfBirth: format(
      new Date(person.dateOfBirth),
      i18n.t('resume.person.dateFormat')!
    ),
    personDateOfBirthLabel: i18n.t('resume.person.dateOfBirth'),
    personNationality: person.nationality,
    personNationalityLabel: i18n.t('resume.person.nationality'),
    personLanguages: person.spokenLanguages.map(({ language }) => language),
    personLanguagesLabel: i18n.t('resume.person.spokenLanguages'),
    experienceTitle: i18n.t('resume.sections.experience.title'),
    experiencePreviewURLTitle: i18n.t('resume.sections.jobs.preview'),
    experienceRepoURLTitle: i18n.t('resume.sections.jobs.repo'),
    experience: experience.map(mapJobToTemplate),

    introTitle: i18n.t('resume.sections.intro.title'),
    intro,

    quotesTitle: i18n.t('resume.sections.quotes.title'),
    quotes: resume.quotes || [],

    skillsTitle: i18n.t('resume.sections.skills.title'),
    skillsLeft,
    skillsRight,

    educationTitle: i18n.t('resume.sections.education.title'),
    educationGraduatedTitle: i18n.t('resume.sections.education.graduated'),
    educationProfileTitle: i18n.t('resume.sections.education.profile'),
    education: resume.education || [],

    characteristicsTitle: i18n.t('resume.sections.characteristics.title'),
    hasCharacteristics: Boolean(person.characteristics),
    characteristicsLeft,
    characteristicsRight,
  };
}
