import React from 'react';
import { Input } from 'antd';
import { FieldProps } from '.';

const StringField = ({ value, onChange, readonly }: FieldProps<string>) => {
  const inputRef = React.useRef(null);

  return (
    <Input
      disabled={readonly}
      ref={inputRef}
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default StringField;
