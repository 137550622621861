import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Space } from 'antd';
import AddRecordButton from '../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../components/CollectionTable/CollectionTable';
import useCollection from '../../hooks/useCollection';
import { Form } from '../../components/Form';
import { updateOrCreate } from '../../components/Form/helpers/createOrUpdate';
import { Employee, EmployeeType, collection } from '../../models/Employee';

const columns: ColumnsType<EmployeeType & { name: string }> = [
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={collection} />,
  },
];

const Employees = () => {
  const [items] = useCollection(Employee);
  const itemsWithName = items.map((i) => ({
    ...i,
    name: `${i.firstName} ${i.lastName}`,
  }));

  return (
    <Space
      style={{ width: '100%', height: '100%', padding: 24, overflow: 'scroll' }}
      direction="vertical"
    >
      <AddRecordButton model={Employee} value="Nieuwe medewerker" />
      <CollectionTable
        values={itemsWithName}
        columns={columns}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(Employee, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={Employee.schema}
            schemaDescription={Employee.schemaDescription}
            getFormProperties={() => Employee.formProperties}
          />
        )}
      />
    </Space>
  );
};

export default Employees;
