import React from 'react';
import { Text, View, Image, Link } from '@react-pdf/renderer';
import { format } from 'fecha';
import { useTranslation } from 'react-i18next';
import { Person } from '../../../types/Resume.types';
import { List, ListItem } from '../List';
import { styles } from './SidePanel.styles';
import githubLogo from '../../../assets/github-logo.png';

export type SidePanelProps = Person;

export const SidePanel: React.ComponentType<SidePanelProps> = ({
  name,
  jobTitle,
  githubUrl,
  cityOfResidence,
  dateOfBirth,
  nationality,
  spokenLanguages,
  characteristics: attributes,
}) => {
  const [t] = useTranslation();

  return (
    <View style={styles.wrapper}>
      <View>
        {/* <View style={styles.infoPair}>
          <Text style={styles.label}>{t('resume.person.name')}:</Text>
          <Text>{name}</Text>
        </View>
        <View style={styles.infoPair}>
          <Text style={styles.label}>{t('resume.person.jobTitle')}:</Text>
          <Text>{jobTitle}</Text>
        </View> */}
        <View style={styles.infoPair}>
          <Text style={styles.label}>{t('resume.person.dateOfBirth')}:</Text>
          <Text>
            {format(new Date(dateOfBirth), t('resume.person.dateFormat')!)}
          </Text>
        </View>
        <View style={styles.infoPair}>
          <Text style={styles.label}>{t('resume.person.nationality')}:</Text>
          <Text>{nationality}</Text>
        </View>
        <View style={styles.infoPair}>
          <Text style={styles.label}>
            {t('resume.person.cityOfResidence')}:
          </Text>
          <Text>{cityOfResidence}</Text>
        </View>
        <View style={styles.infoPair}>
          <Text style={styles.label}>{t('resume.person.attributes')}:</Text>
          <Text>{attributes?.join(', ').toLowerCase()}</Text>
        </View>
        <View style={styles.infoPair}>
          <Text style={styles.label}>
            {t('resume.person.spokenLanguages')}:
          </Text>
          <List>
            {spokenLanguages.map(({ language, proficiency }) => (
              <ListItem key={language}>
                <Text>{language}</Text>
                {proficiency !== 'native' && (
                  <Text style={{ fontSize: 8 }}>
                    ({t(`resume.languageLevels.${proficiency}`)})
                  </Text>
                )}
              </ListItem>
            ))}
          </List>
        </View>
        {githubUrl ? (
          <View>
            <Link src={githubUrl}>
              <Image src={githubLogo} style={{ width: 60 }} />
            </Link>
          </View>
        ) : null}
        {/* {attributes && (
          <View style={styles.infoPair}>
            <Text style={styles.label}>{t('resume.person.attributes')}:</Text>
            <List>
              {attributes.map((attribute) => (
                <ListItem key={attribute}>
                  <Text hyphenationCallback={1}>{attribute}</Text>
                </ListItem>
              ))}
            </List>
          </View>
        )} */}
      </View>
    </View>
  );
};
