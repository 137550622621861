import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {},
  title: {
    fontFamily: 'Neue-Plak',
    textTransform: 'uppercase',
    fontSize: 24,
    color: '#13ca91',
    paddingBottom: 2,
  },
});
