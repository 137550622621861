import React from 'react';
import { Select } from 'antd';
import { FieldProps } from '../../../components/Form/fields';
import { Employee } from '../../../models/Employee';
import useCollection from '../../../hooks/useCollection';

export const EmployeeField = ({
  value,
  onChange,
  readonly,
  errors,
}: FieldProps<string>) => {
  const [items] = useCollection(Employee);
  const options = items.map((employee) => ({
    value: employee.id,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  const fieldErrors = errors.filter((err) => err.path?.match(/^[a-zA-Z]*$/));

  return (
    <>
      <Select
        style={{ width: '100%' }}
        disabled={readonly}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <Select.Option value={option.value} key={option.value}>
            <span>{option.label}</span>
          </Select.Option>
        ))}
      </Select>
      {!readonly && fieldErrors.length > 0 && (
        <div className="form--error">
          {fieldErrors.map((err) => err.message).join('. ')}
        </div>
      )}
    </>
  );
};

export default EmployeeField;
