import React from 'react';
import Menu from 'antd/lib/menu';
import { useHistory } from 'react-router-dom';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import BookOutlined from '@ant-design/icons/BookOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import useSession from '../../hooks/useSession';
import { CodeOutlined } from '@ant-design/icons';

/* Don't use SubMenu, it is broken in the production build.. */

const SideMenu: React.ComponentType = () => {
  const user = useSession();
  const isAdmin = Boolean(user?.admin);
  const history = useHistory();

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[window.location.pathname.substr(1) || 'dashboard']}
      items={[
        {
          key: '0',
          disabled: !isAdmin,
          label: 'Gebruikers',
          onClick: () => history.push('/users'),
          icon: <TeamOutlined />,
        },
        {
          key: '1',
          label: 'Werknemers',
          disabled: !isAdmin,
          onClick: () => history.push('/employees'),
          icon: <SmileOutlined />,
        },
        {
          key: '2',
          label: 'CV',
          onClick: () => history.push('/cv'),
          icon: <BookOutlined />,
        },
        {
          key: '3',
          label: 'Snel CV',
          onClick: () => history.push('/draft-resume'),
          icon: <CodeOutlined />,
        },
        {
          key: '4',
          label: 'Data',
          disabled: !isAdmin,
          onClick: () => history.push('/data'),
          icon: <DatabaseOutlined />,
        },
      ]}
    />
  );
};

export default SideMenu;
