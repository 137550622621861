import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 700,
  },
  large: {
    fontSize: 12,
  },
  small: {
    fontSize: 11,
  },
  at: {
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
  },
});
