export const LANGUAGES = [
  { language: 'nl', flag: 'NL' },
  { language: 'en', flag: 'GB' },
];

export const LANGUAGE_LEVELS = [
  'native',
  'fluent',
  'proficient',
  'intermediate',
];
