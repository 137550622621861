import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '../../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import { updateOrCreate } from '../../../../components/Form/helpers/createOrUpdate';
import {
  collection,
  EmployeeResumeJob,
  EmployeeResumeJobType,
} from '../../../../models/EmployeeResumeJob';
import useQuery from '../../../../hooks/useQuery';
import SkillFormField from '../../../../components/SkillFormField/SkillFormField';

const columns: ColumnsType<EmployeeResumeJobType> = [
  {
    title: 'Bedrijf',
    dataIndex: 'company',
    key: 'company',
    sorter: (a, b) => a.company.localeCompare(b.company),
  },
  {
    title: 'Functie',
    dataIndex: 'job_title',
    key: 'job_title',
    sorter: (a, b) => a.job_title.localeCompare(b.job_title),
  },
  {
    title: 'Start',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'Eind',
    dataIndex: 'end_date',
    key: 'end_date',
  },
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={collection} />,
  },
];

export type ResumeJobsProps = {
  resumeId: string;
};

const ResumeJobs: React.ComponentType<ResumeJobsProps> = ({ resumeId }) => {
  const query = useQuery(EmployeeResumeJob).where('resume_id', '==', resumeId);
  const [items] = useCollection(EmployeeResumeJob, query);
  const customFormFields = { tech_stack: SkillFormField as any };

  return (
    <>
      <AddRecordButton
        model={EmployeeResumeJob}
        value="Nieuwe baan"
        initialValues={{ resume_id: resumeId }}
        customFormFields={customFormFields}
      />
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => {
          return (
            <Form
              onSubmit={(updatedValue) => {
                updateOrCreate(EmployeeResumeJob, updatedValue);
                onClose();
              }}
              onCancel={onClose}
              value={value}
              schema={EmployeeResumeJob.schema}
              schemaDescription={EmployeeResumeJob.schemaDescription}
              getFormProperties={() => EmployeeResumeJob.formProperties}
              customFormFields={customFormFields}
            />
          );
        }}
      />
    </>
  );
};

export default ResumeJobs;
