import { firestore } from 'firebase';
import moment from 'moment';
import { Model } from '../../../models/Model.types';
import { firebaseApp } from '../../../services/firebase';

export const updateSnapshot = async <T>(
  snapshot: firestore.DocumentSnapshot<T>,
  data: Partial<T>
) => snapshot.ref.update(data);

export const createDoc = async <T extends { id: string }>(
  model: Model<T>,
  data: Partial<T>
) => {
  const doc = firebaseApp.firestore().collection(model.collection).doc();
  data.id = doc.id;
  await doc.set(data);

  return doc;
};

export const updateOrCreate = async <T extends { id: string }>(
  model: Model<T>,
  data: Partial<T>
) => {
  if (!data.id) {
    const doc = await createDoc(model, data);
    data.id = doc.id;
  } else {
    const doc = firebaseApp
      .firestore()
      .collection(model.collection)
      .doc(data.id);
    await doc.update(data);
  }
  return data;
};

export const createNew = <T extends { id: string }>(model: Model<T>): T => {
  const newRecord: Record<string, any> = {};
  const keys = Object.keys(model.schemaDescription) as Array<keyof T>;
  keys.forEach((key) => {
    const fieldDescription = model.schemaDescription[key];
    // @ts-ignore
    if (key in model.formProperties && 'default' in model.formProperties[key]) {
      newRecord[key as string] = model.formProperties[key]!.default;
    } else if (fieldDescription.type === 'array') {
      newRecord[key as string] = [];
    } else if (fieldDescription.type === 'string') {
      newRecord[key as string] = '';
    } else if (fieldDescription.type === 'date') {
      newRecord[key as string] = moment().format('YYYY-MM-DD');
    } else {
      newRecord[key as string] = null;
    }
  });
  return newRecord as T;
};
