import React from 'react';
import { View, Image } from '@react-pdf/renderer';
import logoWhite from '../../../assets/logo-white-trans.png';
import logoBlack from '../../../assets/logo-black-trans.png';

export type LogoProps = {
  color?: 'white' | 'black';
  width: number;
  style?: any;
};

export const Logo: React.ComponentType<LogoProps> = ({
  color = 'black',
  width,
  style,
}) => {
  return (
    <View style={style}>
      <Image
        src={color === 'black' ? logoBlack : logoWhite}
        style={{ width }}
      />
    </View>
  );
};
