import React from 'react';
import { Button, Spin } from 'antd';
import GoogleOutlined from '@ant-design/icons/GoogleOutlined';
import { login, useAuth } from '../../hooks/useAuth';
import logo from '../../assets/codecapi_logo.png';
import classes from './Login.module.css';

export const Login: React.ComponentType<{}> = () => {
  const { initializing } = useAuth();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <img style={{ maxWidth: '100%' }} src={logo} alt=''/>

        {initializing ? (
          <Spin />
        ) : (
          <Button
            disabled={initializing}
            icon={<GoogleOutlined />}
            size="large"
            type="primary"
            onClick={login}
          >
            Inloggen
          </Button>
        )}
      </div>
    </div>
  );
};
