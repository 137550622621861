import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
  },
  listItem: {
    paddingBottom: 18,
  },
});
