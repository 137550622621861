import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { firebaseApp } from '../../services/firebase';

interface DeleteButtonProps {
  id: string;
  collection: string;
  canDelete?: boolean;
  deleteFunction?: () => void;
}
const DeleteButton = ({
  id,
  collection,
  canDelete = true,
  deleteFunction = () =>
    firebaseApp.firestore().collection(collection).doc(id).delete(),
}: DeleteButtonProps) => {
  return canDelete ? (
    <Popconfirm
      placement="left"
      cancelText="Annuleren"
      title={'Weet je het zeker?'}
      onConfirm={deleteFunction}
    >
      <Tooltip title="Verwijderen">
        <Button danger shape="circle">
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </Popconfirm>
  ) : (
    <Tooltip title="Verwijderen is momenteel niet mogelijk">
      <Button disabled danger shape="circle">
        <DeleteOutlined />
      </Button>
    </Tooltip>
  );
};

export default DeleteButton;
