import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '../../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import { updateOrCreate } from '../../../../components/Form/helpers/createOrUpdate';
import {
  collection,
  EmployeeResumeProject,
  EmployeeResumeProjectType,
} from '../../../../models/EmployeeResumeProject';
import useQuery from '../../../../hooks/useQuery';
import SkillFormField from '../../../../components/SkillFormField/SkillFormField';
import JobField from './fields/JobField';
import { multiLingualColumn } from '../../../../components/CollectionTable/helpers/multiLingualColumn';

const columns: ColumnsType<EmployeeResumeProjectType> = [
  multiLingualColumn<EmployeeResumeProjectType>(
    {
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    'project_name'
  ),
  {
    title: 'Functie',
    dataIndex: 'job_title',
    key: 'job_title',
    sorter: (a, b) => a.job_title.localeCompare(b.job_title),
  },
  {
    title: 'Start',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'Eind',
    dataIndex: 'end_date',
    key: 'end_date',
  },
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={collection} />,
  },
];

export type ResumeProjectsProps = {
  resumeId: string;
};

const ResumeProjects: React.ComponentType<ResumeProjectsProps> = ({
  resumeId,
}) => {
  const query = useQuery(EmployeeResumeProject).where(
    'resume_id',
    '==',
    resumeId
  );
  const [items] = useCollection(EmployeeResumeProject, query);
  const customFormFields = {
    tech_stack: SkillFormField as any,
    job_id: JobField as any,
  };

  return (
    <>
      <AddRecordButton
        model={EmployeeResumeProject}
        value="Nieuw project"
        initialValues={{ resume_id: resumeId }}
        customFormFields={customFormFields}
      />
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => {
          return (
            <Form
              onSubmit={(updatedValue) => {
                updateOrCreate(EmployeeResumeProject, updatedValue);
                onClose();
              }}
              onCancel={onClose}
              value={value}
              schema={EmployeeResumeProject.schema}
              schemaDescription={EmployeeResumeProject.schemaDescription}
              getFormProperties={() => EmployeeResumeProject.formProperties}
              customFormFields={customFormFields}
            />
          );
        }}
      />
    </>
  );
};

export default ResumeProjects;
