import React from 'react';
import { InputNumber } from 'antd';
import { FieldProps } from '.';

const NumberField = ({ value, onChange, readonly }: FieldProps<number>) => {
  return (
    <InputNumber
      disabled={readonly}
      value={value}
      onChange={(num) => onChange(num as number)}
    />
  );
};

export default NumberField;
