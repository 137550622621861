import React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';
import { styles } from './Details.styles';
import { useTranslation } from 'react-i18next';
import { ProjectDetails } from '../../../../../../types/Resume.types';

export type DetailsProps = ProjectDetails;

export const Details: React.ComponentType<DetailsProps> = ({
  techStack,
  previewURL,
  repoURL,
}) => {
  const [t] = useTranslation();

  return (
    <View>
      {previewURL ? (
        <View style={styles.pairWrapper}>
          <Text style={styles.label}>{t('resume.sections.jobs.preview')}:</Text>
          <Link src={previewURL}>{previewURL}</Link>
        </View>
      ) : null}
      {repoURL ? (
        <View style={styles.pairWrapper}>
          <Text style={styles.label}>{t('resume.sections.jobs.repo')}:</Text>
          <Link src={repoURL}>{repoURL}</Link>
        </View>
      ) : null}
      <View style={styles.pairWrapper}>
        <Text style={styles.label}>{t('resume.sections.jobs.techStack')}:</Text>
        <Text style={styles.value}>{techStack.join(', ')}</Text>
      </View>
    </View>
  );
};
