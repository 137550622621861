import React from 'react';
import { Button, Modal } from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Model, FormPropertiesType } from '../../models/Model.types';
import { createNew, updateOrCreate } from '../Form/helpers/createOrUpdate';
import { Form } from '../Form';
import { FieldProps } from '../Form/fields';

interface AddFirebaseRecordProps<T extends { id: string }> {
  model: Model<T>;
  newRecord?: () => T | Promise<T>;
  changeHook?: (oldValue: T, newValue: T) => T | Promise<T>;
  getFormProperties?: (value: T) => FormPropertiesType<T>;
  customFormFields?: {
    [key in keyof Partial<T>]: React.ComponentType<FieldProps<T>>;
  };
  value?: string;
  initialValues?: Partial<T>;
}
const AddRecordButton = <T extends { id: string }>({
  model,
  newRecord,
  changeHook,
  getFormProperties,
  customFormFields,
  value,
  initialValues,
}: AddFirebaseRecordProps<T>) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [record, setRecord] = React.useState<T | null>(null);

  const onClick = async () => {
    setIsOpen(true);
    if (newRecord) {
      setRecord(await newRecord());
    } else {
      setRecord({ ...createNew(model), ...initialValues });
    }
  };
  const onSubmit = (value: T) => {
    setIsOpen(false);
    updateOrCreate(model, value);
  };
  return (
    <div style={{ paddingBottom: 18 }}>
      <Button
        shape="round"
        type="primary"
        icon={<PlusOutlined />}
        onClick={onClick}
      >
        {value || 'Nieuwe'}
      </Button>
      {isOpen && record && (
        <Modal
          title={value || 'Nieuw'}
          width={960}
          open={isOpen}
          footer={null}
          onCancel={() => setIsOpen(false)}
        >
          <div>
            <Form
              onCancel={() => setIsOpen(false)}
              onSubmit={onSubmit}
              getFormProperties={
                getFormProperties || (() => model.formProperties)
              }
              schemaDescription={model.schemaDescription}
              schema={model.schema}
              changeHook={changeHook}
              value={record}
              customFormFields={customFormFields}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddRecordButton;
