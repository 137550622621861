import * as yup from 'yup';
import { Employee } from './Employee';
import { Model } from './Model.types';
import { Skill } from './Skill';

yup.addMethod(yup.mixed, 'reference', function (key) {
  return this.meta({ model: key });
});

export const Models: Record<string, Model<any>> = {
  // user: User,
  employee: Employee,
  skill: Skill,
};

export default Models;
