import React from 'react';
import Editor, { OnChange } from '@monaco-editor/react';

export type MonacoEditorProps = {
  value: string;
  onChange?: (value: string) => void;
};

export const MonacoEditor: React.FC<MonacoEditorProps> = ({
  onChange,
  value,
}) => {
  const handleChange: OnChange = (value) => {
    if (onChange && value) {
      onChange(value);
    }
  };

  return (
    <Editor
      value={value}
      height="100%"
      width="100%"
      options={{
        fontSize: 14,
        autoClosingBrackets: 'always',
        autoClosingQuotes: 'always',
        autoIndent: 'full',
        autoSurround: 'brackets',
        fixedOverflowWidgets: true,
        scrollBeyondLastLine: false,
        padding: {
          top: 24,
          bottom: 24,
        },
        lineNumbers: 'off',
        minimap: {
          enabled: false,
        },
      }}
      theme="vs-dark"
      language="json"
      onChange={handleChange}
    />
  );
};
