import React from 'react';
import { View } from '@react-pdf/renderer';

export type SidePanelGapFixProps = {
  position: 'top' | 'bottom';
};

export const SidePanelGapFix: React.ComponentType<SidePanelGapFixProps> = ({
  position,
}) => (
  <View
    fixed
    style={{
      backgroundColor: '#f8f8f8',
      borderColor: '#eeeeee',
      borderStyle: 'solid',
      borderRightWidth: 1,
      position: 'absolute',
      left: 0,
      width: 142,
      height: 50,
      ...(position === 'top' ? { top: 0 } : { bottom: 0 }),
    }}
  />
);
