import React from 'react';
import { Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProgressSteps } from '../components/ProgressSteps/ProgressSteps';
import EmployeeResumeGeneralForm from './ResumeGeneral/ResumeGeneral';
import EmployeeResumeSkills from './ResumeSkills/ResumeSkills';
import ResumeJobs from './ResumeJobs/ResumeJobs';
import ResumeProjects from './ResumeProjects/ResumeProjects';
import { NextStepBar } from '../components/NextStepBar/NextStepBar';
import ResumeCharacteristics from './ResumeCharacteristics/ResumeCharacteristics';
import ResumeQuotes from './ResumeQuotes/ResumeQuotes';

export type ResumeDataProps = {
  resumeId: string;
  employeeId: string;
};

export const ResumeData: React.ComponentType<ResumeDataProps> = ({
  resumeId,
  employeeId,
}) => {
  const [t] = useTranslation();

  return (
    <Space
      style={{ width: '100%', height: '100%', overflow: 'scroll', padding: 24 }}
      direction="vertical"
    >
      <ProgressSteps current={3} />
      <NextStepBar
        previousHref={`/cv/werknemer/${employeeId}/cvs`}
        nextHref={`/cv/werknemer/${employeeId}/cvs/${resumeId}/preview`}
      />
      <Tabs defaultActiveKey="1" tabPosition="top" type="line" size="large">
        <Tabs.TabPane tab={t('resumeData.sections.general.title')} key="1">
          <EmployeeResumeGeneralForm resumeId={resumeId} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('resumeData.sections.characteristics.title')}
          key="2"
        >
          <ResumeCharacteristics resumeId={resumeId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('resumeData.sections.skills.title')} key="3">
          <EmployeeResumeSkills resumeId={resumeId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('resumeData.sections.jobs.title')} key="4">
          <ResumeJobs resumeId={resumeId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('resumeData.sections.projects.title')} key="5">
          <ResumeProjects resumeId={resumeId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('resumeData.sections.quotes.title')} key="6">
          <ResumeQuotes resumeId={resumeId} />
        </Tabs.TabPane>
      </Tabs>
    </Space>
  );
};

export default ResumeData;
