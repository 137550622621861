import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  employee_id: yup.string().required().label('Medewerker ID'),
  language_id: yup.string().required().label('Taal ID'),
  level: yup.string().required().label('Niveau'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  employee_id: {
    readonly: true,
  },
};

export const collection = 'employee_language';

export const EmployeeLanguage: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.language_id,
};

export type EmployeeLanguageType = RecordType;
