import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    // backgroundColor: '#13ca91',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    top: 15,
    // left: 0,
    right: 25,
    // height: 40,
    // paddingRight: 25,
  },
});
