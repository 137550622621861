import {
  getResume,
  getResumeFineTuning,
  getResumeGeneral,
  getBasicEmployeeSkills,
  getBasicResumeCharacteristics,
  getBasicResumeJobs,
  getBasicResumeProjects,
} from './genericGetters';
import { createDoc } from '../components/Form/helpers/createOrUpdate';
import { EmployeeResume } from '../models/EmployeeResume';
import { Model } from '../models/Model.types';
import { EmployeeResumeSkill } from '../models/EmployeeResumeSkill';
import { EmployeeResumeFineTune } from '../models/EmployeeResumeFineTune';
import { EmployeeResumeCharacteristic } from '../models/EmployeeResumeCharacteristic';
import { EmployeeResumeGeneral } from '../models/EmployeeResumeGeneral';
import { EmployeeResumeJob } from '../models/EmployeeResumeJob';
import { EmployeeResumeProject } from '../models/EmployeeResumeProject';

const cloneWithoutId = ({ id, ...rest }: any) => rest;

const duplicateSet = async <T extends { id: string; resume_id: string }>(
  data: T[] | T,
  model: Model<T>,
  overwriteData: { [key: string]: any }
) => {
  if (!data) return;
  return Array.isArray(data)
    ? Promise.all(data.map((item) => duplicateDoc(item, model, overwriteData)))
    : duplicateDoc(data, model, overwriteData);
};

const duplicateDoc = async <T extends { id: string; resume_id: string }>(
  data: T,
  model: Model<T>,
  overwriteData: { [key: string]: any }
) => {
  const clonedItem = {
    ...cloneWithoutId(data),
    ...overwriteData,
  };

  return createDoc(model, clonedItem);
};

const duplicateJobsAndProjects = async (
  resumeId: string,
  newResumeId: string
) => {
  const jobs = await getBasicResumeJobs(resumeId);

  for (let job of jobs) {
    const newJob = await duplicateDoc(job, EmployeeResumeJob, {
      resume_id: newResumeId,
    });
    const projects = await getBasicResumeProjects(job.id);

    await duplicateSet(projects, EmployeeResumeProject, {
      resume_id: newResumeId,
      job_id: newJob.id,
    });
  }
};

export const duplicateResume = async (
  resumeId: string,
  createdBy?: string,
  name?: string
) => {
  const [resume, fineTuning, skills, characteristics, general] =
    await Promise.all([
      getResume(resumeId),
      getResumeFineTuning(resumeId),
      getBasicEmployeeSkills(resumeId),
      getBasicResumeCharacteristics(resumeId),
      getResumeGeneral(resumeId),
    ]);

  const newResume = await createDoc(EmployeeResume, {
    ...cloneWithoutId(resume),
    name: name || `${resume.name} (copy)`,
    created_by: createdBy,
  });

  await Promise.all([
    duplicateSet(skills, EmployeeResumeSkill, { resume_id: newResume.id }),
    duplicateSet(fineTuning, EmployeeResumeFineTune, {
      resume_id: newResume.id,
    }),
    duplicateSet(characteristics, EmployeeResumeCharacteristic, {
      resume_id: newResume.id,
    }),
    duplicateSet(general, EmployeeResumeGeneral, { resume_id: newResume.id }),
  ]);
  await duplicateJobsAndProjects(resumeId, newResume.id);
};
