import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Quote } from '../../../../../../types/Resume.types';

export type QuoteItemProps = Quote;

export const QuoteItem: React.ComponentType<QuoteItemProps> = ({
  quote,
  quoteAuthor,
  quoteAuthorTitle,
}) => {
  function getQuoteAuthor() {
    if (quoteAuthor && quoteAuthorTitle) {
      return `- ${quoteAuthor}, ${quoteAuthorTitle}`;
    }
    if (quoteAuthor) {
      return `- ${quoteAuthor}`;
    }
    if (quoteAuthorTitle) {
      return `- ${quoteAuthorTitle}`;
    }
    return '';
  }

  return (
    <>
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <View style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
          <Text style={{ fontSize: '12px', color: '#13ca91' }}>"</Text>
          <Text
            style={{
              fontSize: '12px',
              fontFamily: 'Inter',
              fontStyle: 'italic',
              fontWeight: 500,
            }}
          >
            {quote}
            <Text style={{ fontSize: '12px', color: '#13ca91' }}>"</Text>
          </Text>
        </View>
        <View>
          <Text
            style={{
              fontSize: '10px',
              fontFamily: 'Inter',
              fontWeight: 300,
              alignSelf: 'flex-end',
            }}
          >
            {getQuoteAuthor()}
          </Text>
        </View>
      </View>
    </>
  );
};
