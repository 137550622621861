import axios from 'axios';

export async function getProfileImage(url: string) {
  const imageBlob = await axios.get<Buffer>(url, {
    method: 'GET',
    responseType: 'arraybuffer',
  });

  return imageBlob.data;
}
