import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Space, Avatar } from 'antd';
import CollectionTable from '../../components/CollectionTable/CollectionTable';
import useCollection from '../../hooks/useCollection';
import { Form } from '../../components/Form';
import { updateOrCreate } from '../../components/Form/helpers/createOrUpdate';
import { User, UserType } from '../../models/User';
import { fieldLabel } from '../../components/Form/helpers/fieldLabel';
import DeleteButton from '../../components/DeleteRecordButton/DeleteRecordButton';
import EmployeeField from './fields/EmployeeField';

const columns: ColumnsType<UserType> = [
  {
    title: fieldLabel(User.schemaDescription, 'displayName'),
    dataIndex: 'displayName',
    key: 'name',
    render: (name, record) => (
      <div>
        <Avatar src={record.photoURL} /> {record.displayName}
      </div>
    ),
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.displayName.localeCompare(b.displayName),
  },
  {
    title: fieldLabel(User.schemaDescription, 'email'),
    dataIndex: 'email',
    key: 'email',
    sorter: (a, b) => a.email.localeCompare(b.email),
    responsive: ['md', 'lg', 'xl', 'xxl'],
  },
  {
    title: 'Admin',
    dataIndex: '',
    key: 'x',
    render: (record) => <div>{record.admin ? 'Ja' : 'Nee'}</div>,
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
  },
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={User.collection} />,
  },
];

const Users = () => {
  const [items] = useCollection(User);

  const customFormFields = {
    employeeId: EmployeeField as any,
  };

  return (
    <Space
      style={{ width: '100%', height: '100%', padding: 24, overflow: 'scroll' }}
      direction="vertical"
    >
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(User, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={User.schema}
            schemaDescription={User.schemaDescription}
            getFormProperties={() => User.formProperties}
            customFormFields={customFormFields}
          />
        )}
      />
    </Space>
  );
};

export default Users;
