import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '../../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import { updateOrCreate } from '../../../../components/Form/helpers/createOrUpdate';
import {
  collection,
  EmployeeLanguage,
  EmployeeLanguageType,
} from '../../../../models/EmployeeLanguage';
import useQuery from '../../../../hooks/useQuery';
import LanguageField from './fields/LanguageField';
import { Language, LanguageType } from '../../../../models/Language';
import { getValueInCurrentLanguage } from '../../../../helpers/getValueInLanguage';
import LanguageLevelField from './fields/LanguageLevelField';

const getColumns = (
  allLanguages: LanguageType[]
): ColumnsType<EmployeeLanguageType> => {
  return [
    {
      title: 'Taal',
      dataIndex: 'language_id',
      key: 'language_id',
      render: (data) => {
        return (
          getValueInCurrentLanguage(
            allLanguages.find((item) => item.id === data)?.language
          ) || data
        );
      },
    },
    {
      title: 'Actie',
      dataIndex: 'id',
      key: 'x',
      render: (id) => <DeleteButton id={id} collection={collection} />,
    },
  ];
};

export type EmployeeEducationsProps = {
  employeeId?: string;
};

const EmployeeLanguages: React.ComponentType<EmployeeEducationsProps> = ({
  employeeId,
}) => {
  const query = useQuery(EmployeeLanguage).where(
    'employee_id',
    '==',
    employeeId
  );
  const [items] = useCollection(EmployeeLanguage, query);
  const [allLanguages] = useCollection(Language);
  const customFormFields = {
    language_id: LanguageField as any,
    level: LanguageLevelField as any,
  };

  return (
    <>
      <AddRecordButton
        model={EmployeeLanguage}
        value="Nieuwe taal"
        initialValues={{ employee_id: employeeId }}
        customFormFields={customFormFields}
      />
      <CollectionTable
        values={items}
        columns={getColumns(allLanguages)}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(EmployeeLanguage, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={EmployeeLanguage.schema}
            schemaDescription={EmployeeLanguage.schemaDescription}
            getFormProperties={() => EmployeeLanguage.formProperties}
            customFormFields={customFormFields}
          />
        )}
      />
    </>
  );
};

export default EmployeeLanguages;
