import * as React from 'react';
import { UserType } from '../models/User';

interface UserContext {
  user: UserType | null;
  initializing?: boolean;
}

export const userContext = React.createContext<UserContext>({
  user: null,
});

export const UserProvider = userContext.Provider;
export const UserConsumer = userContext.Consumer;
