import React from 'react';
import {
  SchemaFieldDescription,
  ValidationError,
  SchemaInnerTypeDescription,
  SchemaObjectDescription,
} from 'yup';
import { FormProperty } from '../../../models/Model.types';
import StringField from './StringField';
import NumberField from './NumberField';
import ReferenceField from './ReferenceField';
import BooleanField from './BooleanField';
import TableField from './TableField';
import DateField from './DateField';
import TextAreaField from './TextAreaField';
import MultiLingualField from './MultiLingualField';
import FileField from './FileField';

export interface FieldProps<T, R = any> {
  value: T;
  originalValue: T;
  schemaDescription: SchemaFieldDescription;
  readonly?: boolean;
  errors: Array<ValidationError>;
  onChange: (value: T) => void;
  formProperty?: FormProperty<T>;
  rootValue?: R;
}

const isMultiLingualField = (
  schemaDescription: SchemaFieldDescription
): boolean =>
  schemaDescription.type === 'array' &&
  'innerType' in schemaDescription &&
  (schemaDescription.innerType as SchemaObjectDescription | undefined)?.type ===
    'object' &&
  (schemaDescription.innerType as SchemaObjectDescription)?.fields &&
  typeof (schemaDescription.innerType as SchemaObjectDescription)?.fields
    ?.language !== 'undefined';

const getFieldType = (schemaDescription: SchemaFieldDescription): string => {
  if (isMultiLingualField(schemaDescription)) {
    return (
      (
        (schemaDescription as SchemaInnerTypeDescription)
          ?.innerType as SchemaObjectDescription
      )?.fields?.value?.type || schemaDescription.type
    );
  }

  return schemaDescription.type;
};

export const FormField = React.memo((props: FieldProps<any>) => {
  const { schemaDescription } = props;
  const type = getFieldType(schemaDescription);
  const fieldErrors = props.errors.filter((err) =>
    err.path?.match(/^[a-zA-Z]*$/)
  );
  const FieldComponent: any =
    type === 'string' && props.formProperty?.textarea
      ? TextAreaField
      : type === 'string' && props.formProperty?.file
      ? FileField
      : type === 'string'
      ? StringField
      : type === 'number'
      ? NumberField
      : type === 'boolean'
      ? BooleanField
      : type === 'date'
      ? DateField
      : type === 'mixed' &&
        'meta' in schemaDescription &&
        schemaDescription['meta'] &&
        'model' in schemaDescription.meta
      ? ReferenceField
      : type === 'array' &&
        'innerType' in schemaDescription &&
        (schemaDescription.innerType as SchemaObjectDescription | undefined)
          ?.type === 'object'
      ? TableField
      : // ? ArrayField
        // : type.type === 'object'
        // ? ObjectField
        StringField;
  return (
    <>
      {isMultiLingualField(schemaDescription) ? (
        <MultiLingualField {...props} FieldComponent={FieldComponent} />
      ) : (
        <FieldComponent
          {...props}
          errors={props.errors.filter((error) => !fieldErrors.includes(error))}
        />
      )}
      {!props.readonly && fieldErrors.length > 0 && (
        <div className="form--error">
          {fieldErrors.map((err) => err.message).join('. ')}
        </div>
      )}
    </>
  );
});

export default FormField;
