import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  listItem: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: 10,
  },
  listsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
});
