import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, Popconfirm, Tooltip } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { v4 } from 'uuid';
import { FieldProps } from '.';
import { firebaseApp } from '../../../services/firebase';
import classes from './FileField.module.css';

const FileField = ({
  value,
  onChange,
  readonly,
  errors,
  formProperty = {},
}: FieldProps<string>) => {
  const storageRef = firebaseApp.storage().ref();
  const { basePath = '' } = formProperty;
  const [t] = useTranslation();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const uploads = await Promise.all(
      acceptedFiles.map((file) => {
        const [extension] = file.name.split('.').reverse();
        return storageRef.child(`${basePath}${v4()}.${extension}`).put(file);
      })
    );
    const [url] = await Promise.all(
      uploads.map((item) => item.ref.getDownloadURL())
    );
    onChange(url);
  }, [basePath, onChange, storageRef]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
  });

  return (
    <div
      className={classnames(classes.wrapper, Boolean(errors) && classes.error)}
      {...getRootProps()}
    >
      {value && !isDragActive ? (
        <div className={classes.preview}>
          <Popconfirm
            placement="left"
            cancelText="Annuleren"
            title={'Weet je het zeker?'}
            onConfirm={() => {
              onChange('');
            }}
          >
            <Tooltip title="Verwijderen">
              <Button danger shape="circle">
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
          <img src={value} alt='' />
        </div>
      ) : (
        <>
          <input {...getInputProps()} />

          <div>
            <div className={classes.form}>
              <h3>
                {isDragActive ? t('fileField.drop') : t('fileField.drag')}
              </h3>
              <div className={classes.orLabel}>
                <p>- {t('fileField.or')} -</p>
              </div>
              <Button
                type="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  open();
                }}
                shape="round"
              >
                {t('fileField.select')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FileField;
