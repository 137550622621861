import { EmployeeResumeJobType } from '../models/EmployeeResumeJob';
import { EmployeeResumeProjectType } from '../models/EmployeeResumeProject';

export const sortProject = (
  a: EmployeeResumeProjectType,
  b: EmployeeResumeProjectType
) => {
  if (
    typeof a.order !== 'undefined' &&
    typeof b.order !== 'undefined' &&
    a.order !== null &&
    b.order !== null
  ) {
    return a.order - b.order;
  }

  return a.end_date && b.start_date
    ? new Date(b.start_date).getTime() - new Date(a.end_date).getTime()
    : 1;
};

export const sortJob = (
  jobA: EmployeeResumeJobType,
  jobB: EmployeeResumeJobType
) => {
  if (
    typeof jobA.order !== 'undefined' &&
    typeof jobB.order !== 'undefined' &&
    jobA.order !== null &&
    jobB.order !== null
  ) {
    return jobA.order - jobB.order;
  }

  return jobA.end_date
    ? new Date(jobB.start_date).getTime() - new Date(jobA.end_date).getTime()
    : -1;
};
