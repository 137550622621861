import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../hooks/useCollection';
import { Form } from '../../../components/Form';
import { updateOrCreate } from '../../../components/Form/helpers/createOrUpdate';
import { Skill, SkillType } from '../../../models/Skill';
import TypeField from './fields/TypeField';
import { Space } from 'antd';

const columns: ColumnsType<SkillType> = [
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => a.type.localeCompare(b.type),
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
  },
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={'skills'} />,
  },
];

const Skills = () => {
  const [items] = useCollection(Skill);
  const customFormFields = { type: TypeField as any };

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        height: 'calc(100vh - 170px)',
        overflow: 'scroll',
      }}
    >
      <AddRecordButton
        model={Skill}
        value="Nieuwe skill"
        customFormFields={customFormFields}
      />
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(Skill, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={Skill.schema}
            schemaDescription={Skill.schemaDescription}
            getFormProperties={() => Skill.formProperties}
            customFormFields={customFormFields}
          />
        )}
      />
    </Space>
  );
};

export default Skills;
