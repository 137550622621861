/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { firestore } from 'firebase';
import { Model } from '../models/Model.types';
import { useQuery } from './useQuery';

const useCollection = <T extends object>(
  model: Model<T>,
  initialQuery?: firestore.Query<T>
): [T[], boolean] => {
  const collectionQuery = useQuery(model);
  const query = initialQuery || collectionQuery;
  const [items, setItems] = React.useState<T[]>([]);
  const [done, setDone] = React.useState<boolean>(false);
  React.useEffect(() => {
    return query.onSnapshot(
      (querySnapshot) => {
        setItems(querySnapshot.docs.map((doc) => doc.data()));
        setDone(true);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
        setDone(true);
      }
    );
  }, []);
  return [items, done];
};

export default useCollection;
