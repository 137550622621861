import React from 'react';
import { View, Image, Link } from '@react-pdf/renderer';
import { Job as JobType } from '../../../../../../types/Resume.types';
import { styles } from './Job.styles';
import { ExperienceTimeSpan } from '../ExperienceTimeSpan/ExperienceTimeSpan';
import { List, ListItem } from '../../../../List';
import { Project } from '../Project/Project';
import { Details } from '../Details/Details';
import { Description } from '../Description/Description';
import { TitleWithLocation } from '../../../../TitleWithLocation/TitleWithLocation';

export type JobProps = JobType;

export const Job: React.ComponentType<JobProps> = ({
  jobTitle,
  company,
  description,
  startDate,
  endDate,
  projects,
  techStack,
  repoURL,
  previewURL,
  logo,
}) => {
  const logoItem = logo ? (
    <View style={styles.logoWrapper}>
      <Image src={logo} style={{ maxHeight: 18, maxWidth: 60 }} />
    </View>
  ) : null;

  return (
    <View style={styles.wrapper}>
      <View style={styles.jobHeaderWrapper}>
        <View style={styles.jobHeader}>
          <TitleWithLocation title={jobTitle} location={company} />
          <ExperienceTimeSpan startDate={startDate} endDate={endDate} />
        </View>
        {techStack && techStack.length === 0 && previewURL ? (
          <Link src={previewURL}>{logoItem}</Link>
        ) : (
          logoItem
        )}
      </View>
      {description ? <Description>{description}</Description> : null}
      {techStack && techStack.length > 0 ? (
        <Details
          techStack={techStack}
          repoURL={repoURL}
          previewURL={previewURL}
        />
      ) : null}
      {projects ? (
        <List
          style={{
            ...styles.projectList,
            ...(techStack && techStack.length > 0 ? styles.hasTeckStack : {}),
          }}
        >
          {projects.map((project, index) => (
            <ListItem
              key={index}
              style={index > 0 ? styles.listItem : {}}
              lineFontSize={11}
              type="secondary"
            >
              <Project {...project} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </View>
  );
};
