import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  resume_id: yup.string().required().label('CV ID'),
  skill_id: yup.array().of(yup.string()).label('Skill ID'),
  priority_skill_id: yup.array().of(yup.string()).label('Belangrijke skills'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  skill_id: {
    readonly: true,
  },
};

export const collection = 'employee_resume_skill';

export const EmployeeResumeSkill: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.id,
};

export type EmployeeResumeSkillType = RecordType;
