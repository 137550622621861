import React from 'react';
import { createRoot } from 'react-dom/client';
import { setupI18n } from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

async function renderApp() {
  await setupI18n();

  const root = createRoot(document.getElementById('root')!);
  root.render(<App />);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
