import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, SectionProps } from '../../Section/Section';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Education as EducationType } from '../../../../types/Resume.types';
import { ListItem } from '../../List';
import { EducationItem } from './components/EducationItem/EducationItem';
import { styles } from './Education.styles';

export type EducationProps = { education: EducationType[] } & Pick<
  SectionProps,
  'offset'
>;

export const Education: React.ComponentType<EducationProps> = ({
  education,
  offset,
}) => {
  const [t] = useTranslation();

  return (
    <Section offset={offset}>
      <SectionHeader>{t('resume.sections.education.title')}</SectionHeader>
      {education.map((item, index) => (
        <ListItem key={index} style={styles.listItem}>
          <EducationItem {...item} />
        </ListItem>
      ))}
    </Section>
  );
};
