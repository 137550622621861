//@ts-ignore
import PizZip from 'pizzip';
//@ts-ignore
import Docxtemplater from 'docxtemplater';
//@ts-ignore
// docxtemplater-image-module-free is a fork of docxtemplater-image-module (not maintained anymore)
import ImageModule from 'docxtemplater-image-module-free';
import axios from 'axios';
import { ResumeData } from '../../types/Resume.types';
import { getTemplateVariables } from './library/getTemplateVariables';
import { getProfileImage } from './library/getProfileImage';

export type CreateResumeAttributes = {
  language: 'nl' | 'en';
  author?: string;
  creator?: string;
  title?: string;
  subject?: string;
  resume: ResumeData;
};

export async function createResume({
  resume,
  title,
  creator,
  subject,
}: CreateResumeAttributes): Promise<Blob> {
  const template = await axios.get<Buffer>('/template.docx', {
    method: 'GET',
    responseType: 'arraybuffer',
  });
  const profileImage = await getProfileImage(resume.person.imageURL as string);

  const imageModule = new ImageModule({
    centered: false,
    getImage: () => profileImage,
    getSize: () => [180, 180],
  });

  const zip = new PizZip(template.data);
  const templater = new Docxtemplater(zip, {
    paragraphLoop: true,
    linebreaks: true,
    modules: [imageModule],
  });

  const docx = templater.setData(getTemplateVariables(resume)).render();

  return docx.getZip().generate({ type: 'blob' }) as Blob;
}
