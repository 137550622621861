import React from 'react';
import { useTranslation } from 'react-i18next';
import useCollection from '../../../../hooks/useCollection';
import {
  updateOrCreate,
  createNew,
} from '../../../../components/Form/helpers/createOrUpdate';
import { EmployeeResumeSkill } from '../../../../models/EmployeeResumeSkill';
import { Skill, SkillType } from '../../../../models/Skill';
import useQuery from '../../../../hooks/useQuery';
import { SkillAutoComplete } from '../../../../components/SkillAutoComplete/SkillAutoComplete';
import SkillList from '../../../../components/SkillList/SkillList';
import { firebaseApp } from '../../../../services/firebase';

export type EmployeeResumeSkillsProps = {
  resumeId: string;
};

export const EmployeeResumeSkills: React.ComponentType<
  EmployeeResumeSkillsProps
> = ({ resumeId }) => {
  const [t] = useTranslation();
  const queryResumeSkills = useQuery(EmployeeResumeSkill).where(
    'resume_id',
    '==',
    resumeId
  );
  const [[resumeSkills]] = useCollection(
    EmployeeResumeSkill,
    queryResumeSkills
  );
  const [allSkills] = useCollection(Skill);
  const resumeSkillIds = resumeSkills?.skill_id as string[] ?? [];
  const prioritySkillIds = resumeSkills?.priority_skill_id as string[] ?? [];

  const handleSkillsUpdate = (
    newSkills: string[],
    prioritySkills: string[]
  ) => {
    const record = resumeSkills
      ? {
          ...resumeSkills,
          skill_id: newSkills,
          priority_skill_id: prioritySkills,
        }
      : {
          ...createNew(EmployeeResumeSkill),
          resume_id: resumeId,
          skill_id: newSkills,
          priority_skill_id: prioritySkills,
        };

    updateOrCreate(EmployeeResumeSkill, record);
  };

  const handleSkillSelect = (item: SkillType) => {
    handleSkillsUpdate(
      [...(resumeSkillIds as string[]), item.id],
      prioritySkillIds as string[]
    );
  };

  const handleRemoveSkill = (id: string) => {
    handleSkillsUpdate(
      resumeSkillIds.filter((skillId) => skillId !== id),
      prioritySkillIds
    );
  };

  const mappedResumeSkills = resumeSkillIds
    ?.map((skill) => {
      return allSkills.find((s) => s.id === skill);
    })
    .filter(Boolean) as SkillType[];

  const onOrderChange = (items: SkillType[]) => {
    const doc = firebaseApp
      .firestore()
      .collection(EmployeeResumeSkill.collection)
      .doc(resumeSkills.id);

    doc.update({
      skill_id: items.map((item) => item.id),
    });
  };

  return (
    <>
      <SkillAutoComplete
        exclude={resumeSkillIds}
        onSelect={handleSkillSelect}
      />
      <div>
        <h2 style={{ paddingTop: 24 }}>
          {t('resumeData.sections.skills.addedSkills')}
        </h2>
        <SkillList
          onDelete={(id) => handleRemoveSkill(id)}
          skills={mappedResumeSkills}
          onOrderChange={onOrderChange}
        />
      </div>
    </>
  );
};

export default EmployeeResumeSkills;
