import React from 'react';
import { View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Education } from '../../../../../../types/Resume.types';
import { TimeSpan } from '../../../../TimeSpan/TimeSpan';
import { TitleWithLocation } from '../../../../TitleWithLocation/TitleWithLocation';
import { Paragraph } from '../../../../Paragraph/Paragraph';

export type EducationItemProps = Education;

export const EducationItem: React.ComponentType<EducationItemProps> = ({
  startYear,
  endYear,
  name,
  school,
  profile,
  note,
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <TitleWithLocation title={name} location={school} />
      <TimeSpan start={startYear?.toString()} end={endYear?.toString()} />
      {profile ? (
        <Paragraph size="normal">
          {t('resume.sections.education.profile')}: {profile}
        </Paragraph>
      ) : null}
      {note ? <Paragraph size="normal">{note}</Paragraph> : null}
    </View>
  );
};
