import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {},
  projectHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 350,
  },
  projectHeader: {
    paddingBottom: 6,
    flexGrow: 1,
  },
  logoWrapper: {
    textAlign: 'right',
  },
});
