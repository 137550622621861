import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../hooks/useCollection';
import { Form } from '../../../components/Form';
import { updateOrCreate } from '../../../components/Form/helpers/createOrUpdate';
import { Language, LanguageType, collection } from '../../../models/Language';
import { multiLingualColumn } from '../../../components/CollectionTable/helpers/multiLingualColumn';
import { Space } from 'antd';

const columns: ColumnsType<LanguageType> = [
  multiLingualColumn(
    {
      title: 'Taal',
      dataIndex: 'language',
      key: 'language',
    },
    'language'
  ),
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={collection} />,
  },
];

const Languages = () => {
  const [items] = useCollection(Language);

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        height: 'calc(100vh - 170px)',
        overflow: 'scroll',
      }}
    >
      <AddRecordButton model={Language} value="Nieuwe taal" />
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(Language, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={Language.schema}
            schemaDescription={Language.schemaDescription}
            getFormProperties={() => Language.formProperties}
          />
        )}
      />
    </Space>
  );
};

export default Languages;
