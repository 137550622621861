import React, { useMemo, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { PDFResume } from '../../components/pdf-resume/PDFResume';
import { DocxResume } from '../../components/docx-resume/DocxResume';
import { DownloadBar } from '../Resume/components/DownloadBar/DownloadBar';
import { ResumeData } from '../../types/Resume.types';
import classes from './DrafResume.module.css';
import { MonacoEditor } from '../../components/MonacoEditor/MonacoEditor';
import { Space } from 'antd';

export type DrafResumeProps = {
  resumeId: string;
  employeeId: string;
};

export const DrafResume: React.ComponentType<DrafResumeProps> = () => {
  const history = useHistory();
  const [, i18n] = useTranslation();
  const defaultResume: ResumeData = {
    experience: [],
    intro: '',
    person: {
      cityOfResidence: '',
      dateOfBirth: new Date().toISOString(),
      firstName: '',
      imageURL: '',
      jobTitle: '',
      lastName: '',
      nationality: '',
      spokenLanguages: [],
      characteristics: [],
      githubUrl: '',
      name: '',
    },
    sectionFineTuning: {},
    skills: [],
    education: [],
  };
  const [resumeValue, setResumeValue] = useState<string>(
    JSON.stringify(defaultResume, undefined, 2)
  );
  const [resumeJSON] = useDebounce(resumeValue, 1000);
  const [anonymous, setAnonymous] = useState(false);
  const lang = i18n.language as 'nl' | 'en';
  const resume: ResumeData | null = useMemo(() => {
    try {
      if (resumeJSON) {
        const data = JSON.parse(resumeJSON) as ResumeData;
        console.log(JSON.stringify(data));
        return {
          ...data,
          person: {
            ...data.person,
            imageURL: data.person?.imageURL || '/assets/stock-photo.jpg',
          },
        };
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  }, [resumeJSON]);

  const resumePreview = useMemo(() => {
    return resume ? (
      <PDFViewer height="100%" width="100%">
        <PDFResume {...resume} language={lang} />
      </PDFViewer>
    ) : null;
  }, [lang, resume]);

  const resumeName = resume
    ? `${resume.person.name} - [code]capi - [${lang.toUpperCase()}]`
    : '';

  const basePath = `/draft-resume`;

  return (
    <Space
      style={{ width: '100%', height: '100%', padding: 24, overflow: 'scroll' }}
      direction="vertical"
    >
      {resume && (
        <Switch>
          <Route
            path={`${basePath}/pdf/download`}
            exact
            render={() => (
              <PDFDownloadLink
                document={
                  <PDFResume {...(resume as ResumeData)} language={lang} />
                }
              >
                {({ blob }) => {
                  if (blob) {
                    saveAs(blob, resumeName);
                    setTimeout(() => history.replace(basePath), 3000);
                  }

                  return null;
                }}
              </PDFDownloadLink>
            )}
          />
          <Route
            path={`${basePath}/docx/download`}
            exact
            render={() => (
              <DocxResume resume={resume as ResumeData} language={lang}>
                {(blob) => {
                  if (blob) {
                    saveAs(blob, `${resumeName}.docx`);
                    setTimeout(() => history.replace(basePath), 3000);
                  }
                }}
              </DocxResume>
            )}
          />
        </Switch>
      )}
      <div className={classes.wrapper}>
        <div className={classes.form}>
          <MonacoEditor value={resumeValue} onChange={setResumeValue} />
        </div>
        <div className={classes.preview}>
          <DownloadBar
            basePath={basePath}
            anonymous={anonymous}
            onChangeAnonymous={() => setAnonymous(!anonymous)}
          />
          {resumePreview}
        </div>
      </div>
    </Space>
  );
};
