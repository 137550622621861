import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    fontFamily: 'Inter',
    fontSize: 9,
    fontWeight: 300,
    color: '#666666',
  },
});
