import React from 'react';
import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
// import { hyphenateSync } from 'hyphen/nl';
import { Profile } from './sections/Profile/Profile';
import { ResumeData } from '../../types/Resume.types';

//@ts-ignore
import NeuePlak from '../../assets/NeuePlak-CompBlack.ttf';
//@ts-ignore
import InterRegular from '../../assets/Inter-Regular.ttf';
//@ts-ignore
import InterMedium from '../../assets/Inter-Medium.ttf';
//@ts-ignore
import InterBold from '../../assets/Inter-Bold.ttf';
//@ts-ignore
import InterSemiBold from '../../assets/Inter-SemiBold.ttf';
//@ts-ignore
import InterSemiBoldItalic from '../../assets/Inter-SemiBoldItalic.woff';
import { Intro } from './sections/Intro/Intro';
import { Quote } from './sections/Quotes/Quote';
import { Skills } from './sections/Skills/Skills';
import { Experience } from './sections/Experience/Experience';
import { Footer } from './Footer/Footer';
import { Education } from './sections/Education/Education';
import { Header } from './Header/Header';
import { SidePanel } from './SidePanel/SidePanel';
import { SidePanelGapFix } from './SidePanel/SidePanelGapFix';

Font.register({
  family: 'Neue-Plak',
  fonts: [
    {
      src: NeuePlak,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: NeuePlak,
      fontStyle: 'italic',
    },
  ],
});

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: InterMedium,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: InterSemiBold,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: InterBold,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: InterSemiBoldItalic,
      fontStyle: 'italic',
      fontWeight: 500,
    },
  ],
});

const hyphenationCallback = (word: string) => {
  return [word];
  // return hyphenateSync(word, { hyphenChar: '%' }).split('%');
};

Font.registerHyphenationCallback(hyphenationCallback);

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: '#1e1e1e',
    paddingTop: 60,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
});

export type PDFResumeProps = ResumeData & {
  language: 'nl' | 'en';
};

// Create Document Component
export const PDFResume: React.FC<PDFResumeProps> = ({
  person,
  intro,
  skills,
  experience,
  education,
  sectionFineTuning,
  language,
  quotes,
}) => {
  return (
    // @ts-ignore
    <Document
      author="CodeCapi B.V."
      creator="CodeCapi B.V."
      subject={`${person.name} - ${person.jobTitle}`}
      title={`${person.name} - [code]capi - ${language.toUpperCase()}`}
    >
      <Page size="A4" style={styles.page} wrap={true} orientation="portrait">
        <SidePanel {...person} />
        <SidePanelGapFix position="bottom" />
        <Header />
        <Footer />
        <Profile {...person} />
        <Intro>{intro}</Intro>
        {quotes && quotes.length > 0 && (
          <Quote offset={sectionFineTuning.quotesOffset} quotes={quotes} />
        )}
        <Skills offset={sectionFineTuning.skillsOffset} skills={skills} />
        <Experience
          offset={sectionFineTuning.experienceOffset}
          jobs={experience}
        />
        {education ? (
          <Education
            offset={sectionFineTuning.educationOffset}
            education={education}
          />
        ) : null}
      </Page>
    </Document>
  );
};
