import React from 'react';
import { Text } from '@react-pdf/renderer';
import { styles } from './Paragraph.styles';

export type ParagraphProps = {
  style?: any;
  size?: 'normal' | 'large';
  center?: boolean;
  children: React.ReactNode;
};

export const Paragraph: React.ComponentType<ParagraphProps> = ({
  children,
  center = false,
  size = 'normal',
  style = {},
}) => (
  <Text
    hyphenationCallback={(word) => [word]}
    style={{
      ...styles.wrapper,
      ...styles[size],
      ...(center && styles.center),
      ...style,
    }}
  >
    {children}
  </Text>
);
