import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Button, Table } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { fieldLabel } from '../helpers/fieldLabel';
import FormField, { FieldProps } from '.';

const TableField = ({
  value,
  schemaDescription,
  onChange,
  errors,
}: FieldProps<Array<Record<string, any>>>) => {
  const dataSource = value.map((v: any, i: number) => ({ ...v, key: i }));
  if (
    !('innerType' in schemaDescription) ||
    schemaDescription.innerType === undefined
  ) {
    return null;
  }
  if (!('fields' in schemaDescription.innerType)) {
    return null;
  }
  const subType = schemaDescription.innerType!.fields!;
  const columns: ColumnsType<any> = Object.keys(subType).map((f) => ({
    title: fieldLabel(subType, f),
    dataIndex: f,
    key: f,
    render: (val, r) => (
      <FormField
        originalValue={val}
        value={val}
        onChange={(changedVal) => {
          value[r.key][f] = changedVal;
          onChange(value);
        }}
        errors={[]}
        schemaDescription={subType[f]}
      />
    ),
  }));
  columns.push({
    title: '',
    render: (r) => (
      <Button
        danger
        shape="circle"
        onClick={() => {
          value.splice(r.key, 1);
          onChange(value);
        }}
      >
        <DeleteOutlined />
      </Button>
    ),
  });
  const errorsGroupedPerRow = errors.reduce((acc: string[], err) => {
    const matches = err.path?.match(/[a-zA-Z]\[(.*)\]/);
    if (matches) {
      const row = Number(matches[1]);
      acc[row] = (acc[row] || '') + err.message + '. ';
    }
    return acc;
  }, []);

  return (
    <div style={{ width: '100%' }}>
      {value.length > 0 && (
        <Table
          rowClassName={(record, index) =>
            errorsGroupedPerRow[index] ? 'ant-alert-error' : ''
          }
          pagination={false}
          dataSource={dataSource}
          columns={columns}
        />
      )}
      {errorsGroupedPerRow.map((msg, index) => (
        <div key={index} className="form--error">{`Rij ${
          index + 1
        }: ${msg}`}</div>
      ))}
      <Button
        type="primary"
        shape="circle"
        onClick={() => onChange([...value, {}])}
      >
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default TableField;
