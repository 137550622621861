import { firestore } from 'firebase';
import { Employee } from '../models/Employee';
import { firebaseApp } from '../services/firebase';
import { Model } from '../models/Model.types';
import { EmployeeResume } from '../models/EmployeeResume';
import {
  EmployeeLanguage,
} from '../models/EmployeeLanguage';
import { EmployeeResumeSkill } from '../models/EmployeeResumeSkill';
import { Skill, SkillType } from '../models/Skill';
import { Language } from '../models/Language';
import { EmployeeResumeGeneral } from '../models/EmployeeResumeGeneral';
import { EmployeeResumeCharacteristic } from '../models/EmployeeResumeCharacteristic';
import { Characteristic } from '../models/Characteristic';
import { EmployeeEducation } from '../models/EmployeeEducation';
import { EmployeeResumeJob } from '../models/EmployeeResumeJob';
import { EmployeeResumeProject } from '../models/EmployeeResumeProject';
import { EmployeeResumeQuotes } from '../models/EmployeeResumeQuote';
import { EmployeeResumeFineTune } from '../models/EmployeeResumeFineTune';
import { LANGUAGE_LEVELS } from '../constants/languages';
import { sortJob, sortProject } from './genericSorters';

export const getQuery = <T extends object>(model: Model<T>) =>
  firebaseApp
    .firestore()
    .collection(model.collection) as firestore.CollectionReference<T>;

export const getCollecion = async <T extends object>(
  query: firestore.Query<T>
) => {
  const snapshot = await query.get();

  return snapshot.docs.map((doc) => doc.data());
};

export const getResumeQuery = (resumeId: string) => {
  return getQuery(EmployeeResume).where('id', '==', resumeId);
};

export const getResume = async (resumeId: string) => {
  const query = getResumeQuery(resumeId);
  const [resume] = await getCollecion(query);

  return resume;
};

export const getEmployee = async (employeeId: string) => {
  const query = getQuery(Employee).where('id', '==', employeeId);
  const [employee] = await getCollecion(query);

  return employee;
};

export const getEmployeeLanguages = async (employeeId: string) => {
  const query = getQuery(EmployeeLanguage).where(
    'employee_id',
    '==',
    employeeId
  );
  const data = await getCollecion(query);
  const allLanguagesQuery = getQuery(Language);
  const allLanguages = await getCollecion(allLanguagesQuery);

  return data
    .map((item) => {
      const language = allLanguages.find(
        (lang) => lang.id === item.language_id
      );

      if (!language) {
        throw Error(`Language not found with ID: ${item.language_id}`);
      }
      return {
        ...item,
        ...language,
      };
    })
    .sort(
      (a, b) =>
        LANGUAGE_LEVELS.indexOf(a.level) - LANGUAGE_LEVELS.indexOf(b.level)
    );
};

export const getResumeGeneralQuery = (resumeId: string) =>
  getQuery(EmployeeResumeGeneral).where('resume_id', '==', resumeId);

export const getResumeGeneral = async (resumeId: string) => {
  const query = getResumeGeneralQuery(resumeId);
  const [data] = await getCollecion(query);

  return data;
};

export const getResumeCharacteristicsQuery = (resumeId: string) =>
  getQuery(EmployeeResumeCharacteristic).where('resume_id', '==', resumeId);

export const getBasicResumeCharacteristics = async (resumeId: string) => {
  const query = getResumeCharacteristicsQuery(resumeId);
  const data = await getCollecion(query);

  return data;
};

export const getResumeCharacteristics = async (resumeId: string) => {
  const allQuery = getQuery(Characteristic);
  const [data] = await getBasicResumeCharacteristics(resumeId);
  const allItems = (await getCollecion(allQuery)).sort(
    (a, b) => a.order - b.order
  );

  return allItems.filter(
    (item) => data?.characteristic_id.includes(item.id) || false
  );
};

export const getSkillsWithIds = async (skills: string[]) => {
  const query = getQuery(Skill);
  const data = await getCollecion(query);

  return skills
    .map((skill) => data.find(({ id }) => skill === id))
    .filter(Boolean) as SkillType[];
};

export const getBasicEmployeeSkillsQuery = (resumeId: string) =>
  getQuery(EmployeeResumeSkill).where('resume_id', '==', resumeId);

export const getBasicEmployeeSkills = async (resumeId: string) => {
  const query = getBasicEmployeeSkillsQuery(resumeId);
  const data = await getCollecion(query);

  return data;
};

export const getEmployeeSkills = async (resumeId: string) => {
  const [data] = await getBasicEmployeeSkills(resumeId);
  const skills = await getSkillsWithIds(data?.skill_id as string[]);

  return skills.map((item) => ({
    ...item,
    priority: data.priority_skill_id?.includes(item.id),
  }));
};

export const getEmployeeEducation = async (employeeId: string) => {
  const query = getQuery(EmployeeEducation).where(
    'employee_id',
    '==',
    employeeId
  );
  const data = await getCollecion(query);

  return data;
};

export const getResumeProjectsQuery = (resumeId: string) =>
  getQuery(EmployeeResumeProject).where('resume_id', '==', resumeId);

export const getBasicResumeProjects = async (jobId: string) => {
  const query = getQuery(EmployeeResumeProject).where('job_id', '==', jobId);
  const data = await getCollecion(query);

  return data.sort(sortProject);
};

export const getBasicResumeProjectsByResumeId = async (resumeId: string) => {
  const query = getQuery(EmployeeResumeProject).where(
    'resume_id',
    '==',
    resumeId
  );
  const data = await getCollecion(query);

  return data;
};

export const getResumeProjects = async (jobId: string) => {
  const data = await getBasicResumeProjects(jobId);
  const resolvedSkills = await Promise.all(
    data.map(async (item) => ({
      ...item,
      tech_stack: await getSkillsWithIds(item.tech_stack as string[]),
    }))
  );

  return resolvedSkills;
};

export const getResumeJobsQuery = (resumeId: string) =>
  getQuery(EmployeeResumeJob).where('resume_id', '==', resumeId);

export const getBasicResumeJobs = async (resumeId: string) => {
  const query = getResumeJobsQuery(resumeId);
  const data = await getCollecion(query);

  return data.sort(sortJob);
};

export const getResumeJobs = async (resumeId: string) => {
  const data = await getBasicResumeJobs(resumeId);
  const resolvedSkills = await Promise.all(
    data.map(async (item) => ({
      ...item,
      tech_stack: await getSkillsWithIds(item.tech_stack as string[]),
    }))
  );

  return resolvedSkills;
};

export const getResumeQuotes = async (resumeId: string) => {
  const query = getQuery(EmployeeResumeQuotes).where(
    'resume_id',
    '==',
    resumeId
  );

  const data = await getCollecion(query);

  return data;
};

export const getResumeFineTuningQuery = (resumeId: string) =>
  getQuery(EmployeeResumeFineTune).where('resume_id', '==', resumeId);

export const getResumeFineTuning = async (resumeId: string) => {
  const query = getResumeFineTuningQuery(resumeId);
  const [data] = await getCollecion(query);

  return data;
};
