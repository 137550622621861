import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    marginTop: -60,
    color: '#f8f8f8',
    paddingLeft: 12,
    paddingTop: 30,
    paddingRight: 15,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleWrapper: {
    top: -25,
  },
  logo: {
    position: 'absolute',
    right: 25,
    top: 15,
  },
  title: {
    fontFamily: 'Neue-Plak',
    fontSize: 64,
    lineHeight: 1.3,
    textTransform: 'uppercase',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: 12,
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  imageWrapper: {
    padding: 11,
    width: 140,
    height: 140,
    marginRight: 14,
    top: -11,
  },
  imageCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
