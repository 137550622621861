import React from 'react';
import { Select } from 'antd';
import { FieldProps } from '../../../../components/Form/fields';
import { SKILL_TYPES } from '../../../../constants/skillTypes';

export const TypeField = ({
  value,
  onChange,
  readonly,
  errors,
}: FieldProps<string>) => {
  const options = SKILL_TYPES;

  const fieldErrors = errors.filter((err) => err.path?.match(/^[a-zA-Z]*$/));

  return (
    <>
      <Select
        style={{ width: '100%' }}
        disabled={readonly}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <Select.Option value={option} key={option}>
            <span>{option}</span>
          </Select.Option>
        ))}
      </Select>
      {!readonly && fieldErrors.length > 0 && (
        <div className="form--error">
          {fieldErrors.map((err) => err.message).join('. ')}
        </div>
      )}
    </>
  );
};

export default TypeField;
