import 'firebase/auth';
import 'firebase/firestore';
import { DEPLOYMENT_MODE, settings } from '../../settings';

export const firebaseProductionConfig = {
  apiKey: 'AIzaSyDtH0e_LCt1sMuWteVFtRhiH5JsEktfu7M',
  authDomain: 'codecapi-resume.firebaseapp.com',
  databaseURL: 'https://codecapi-resume.firebaseio.com',
  projectId: 'codecapi-resume',
  storageBucket: 'codecapi-resume.appspot.com',
  messagingSenderId: '7640428032',
  appId: '1:7640428032:web:04d2f81de52cf59814b2e5',
};

export const firebaseStagingConfig = {
  apiKey: "AIzaSyBxhMNCHjljqP2cO9x9_Mt2jG3thTUQKW0",
  authDomain: "codecapi-resume-staging.firebaseapp.com",
  databaseURL: 'https://codecapi-resume-staging.firebaseio.com',
  projectId: "codecapi-resume-staging",
  storageBucket: "codecapi-resume-staging.appspot.com",
  messagingSenderId: "1041678778571",
  appId: "1:1041678778571:web:f5590b72c59a3e64fc1b4e"
}

export const firebaseConfig =
    settings.deploymentMode === DEPLOYMENT_MODE.PRODUCTION ? firebaseProductionConfig : firebaseStagingConfig;