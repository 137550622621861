import React from 'react';
import { Card } from 'antd';
import { EmployeeResumeProjectType } from '../../../../models/EmployeeResumeProject';
import { getValueInCurrentLanguage } from '../../../../helpers/getValueInLanguage';
import { useTranslation } from 'react-i18next';
import { format } from 'fecha';

export type ResumeOrderProjectProps = {
  project: EmployeeResumeProjectType;
  [key: string]: any;
};

export const ResumeOrderProject: React.ComponentType<
  ResumeOrderProjectProps
> = ({ project, children, ...rest }) => {
  const [t] = useTranslation();
  const formattedStartDate = project.start_date
    ? format(
        new Date(project.start_date),
        t('resume.sections.jobs.dateFormat')!
      )
    : null;
  const formattedEndDate = project.end_date
    ? format(new Date(project.end_date), t('resume.sections.jobs.dateFormat')!)
    : t('resume.sections.jobs.present');

  const description = formattedStartDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : null;
  const title = `${project.job_title} @ ${getValueInCurrentLanguage(
    project.project_name
  )}`;

  return (
    <Card {...rest}>
      <Card.Meta
        title={title}
        description={description}
        style={{ marginBottom: 20 }}
      />
      {getValueInCurrentLanguage(project.description)}
      {children}
    </Card>
  );
};
