import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {},
  jobHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 370,
  },
  jobHeader: {
    paddingBottom: 6,
    flexGrow: 1,
  },
  logoWrapper: {
    textAlign: 'right',
  },
  listItem: {
    paddingTop: 18,
  },
  hasTeckStack: {
    paddingTop: 18,
  },
  projectList: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  pairWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 300,
    paddingTop: 1,
    paddingBottom: 1,
  },
  label: {
    fontWeight: 700,
    marginRight: 6,
  },
});
