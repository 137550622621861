import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '../../Section/Section';
import { Paragraph } from '../../Paragraph/Paragraph';
import { SectionHeader } from '../../SectionHeader/SectionHeader';

export type IntroProps = {
  children: React.ReactNode;
};

export const Intro: React.ComponentType<IntroProps> = ({ children }) => {
  const [t] = useTranslation();

  return (
    <Section>
      <SectionHeader>{t('resume.sections.intro.title')}</SectionHeader>
      <Paragraph size="large">{children}</Paragraph>
    </Section>
  );
};
