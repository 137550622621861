import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';
import { getValueInCurrentLanguage } from '../helpers/getValueInLanguage';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  resume_id: yup.string().required().label('CV ID'),
  quote: multilingualSchema(yup.string()).optional().label('Quote'),
  quote_author: yup.string().optional().label('Afzender quote'),
  quote_author_title: yup.string().optional().label('Titel quote afzender'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
    hidden: true,
  },
  resume_id: {
    readonly: true,
    hidden: true,
  },
  quote: {
    textarea: true,
  },
};

export const collection = 'employee_resume_quotes';

export const EmployeeResumeQuotes: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => getValueInCurrentLanguage(data.quote),
};

export type EmployeeResumeQuote = RecordType;
