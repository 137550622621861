import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';
import { getValueInCurrentLanguage } from '../helpers/getValueInLanguage';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  language: multilingualSchema(yup.string()).required().label('Taal'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
};

export const collection = 'languages';

export const Language: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => getValueInCurrentLanguage(data.language),
};

export type LanguageType = RecordType;
