import React from 'react';
import { Layout as AntdLayout } from 'antd';
import Header from '../Header/Header';
import SideMenu from '../SideMenu/SideMenu';

const { Header: AntdHeader, Sider, Content } = AntdLayout;

type LayoutProps = {
  children: React.ReactNode;
};

const layoutStyle: React.CSSProperties = {
  height: '100%',
  width: '100%',
  overflow: 'hidden',
};

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  height: 64,
  paddingInline: 24,
  lineHeight: '64px',
};

export const Layout: React.ComponentType<LayoutProps> = ({ children }) => {
  return (
    <AntdLayout style={layoutStyle}>
      <AntdHeader style={headerStyle}>
        <Header />
      </AntdHeader>

      <AntdLayout style={layoutStyle}>
        <Sider collapsible collapsedWidth={60} breakpoint="md">
          <SideMenu />
        </Sider>
        <AntdLayout>
          <Content>{children}</Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
};
