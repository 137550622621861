import React from 'react';
import { Section, SectionProps } from '../../Section/Section';
import { Quote as QuoteType } from '../../../../types/Resume.types';
import { QuoteItem } from './components/QuoteItem/QuoteItem';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { useTranslation } from 'react-i18next';

export type QuoteProps = { quotes: QuoteType[] } & Pick<SectionProps, 'offset'>;

export const Quote: React.ComponentType<QuoteProps> = ({ quotes, offset }) => {
  const { t } = useTranslation();

  return (
    <>
      {quotes.length > 0 && quotes[0].quote !== '' ? (
        <Section offset={offset}>
          <SectionHeader>{t('resume.sections.quotes.title')}</SectionHeader>
          {quotes.map((quote, index) => (
            <QuoteItem key={index} {...quote} />
          ))}
        </Section>
      ) : null}
    </>
  );
};
