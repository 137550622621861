import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { Space, Tabs } from 'antd';
import { saveAs } from 'file-saver';
import { PDFResume } from '../../../components/pdf-resume/PDFResume';
import { DocxResume } from '../../../components/docx-resume/DocxResume';
import { useTranslation } from 'react-i18next';
import classes from './ResumePreview.module.css';
import { DownloadBar } from '../components/DownloadBar/DownloadBar';
import { ProgressSteps } from '../components/ProgressSteps/ProgressSteps';
import { NextStepBar } from '../components/NextStepBar/NextStepBar';
import { getResumeData } from '../../../helpers/getResumeData';
import { ResumeData } from '../../../types/Resume.types';
import ResumeFineTuning from './ResumeFineTuning/ResumeFineTuning';
import { ResumeOrderJobList } from './ResumeOrderJob/ResumeOrderJobList';

export type ResumePreviewProps = {
  resumeId: string;
  employeeId: string;
};

export const ResumePreview: React.ComponentType<ResumePreviewProps> = ({
  employeeId,
  resumeId,
}) => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const [resume, setResume] = useState<ResumeData | null>(null);
  const [anonymous, setAnonymous] = useState(false);
  const lang = i18n.language as 'nl' | 'en';

  const loadData = React.useCallback(
    (lang: string) => {
      (async () => {
        const data = await getResumeData(resumeId, employeeId, anonymous, lang);
        setResume(data);
      })();
    },
    [anonymous, employeeId, resumeId]
  );

  const handleUpdate = () => loadData(lang);

  React.useEffect(() => {
    loadData(lang);
  }, [lang, anonymous, loadData]);

  const resumePdf = React.useMemo(() => {
    if (resume) {
      return <PDFResume {...resume} language={lang} />;
    }
    return <></>;
  }, [resume, lang]);

  if (!resume) {
    return null;
  }

  const resumeName = `${
    resume.person.name
  } - [code]capi - [${lang.toUpperCase()}]`;

  const basePath = `/cv/werknemer/${employeeId}/cvs/${resumeId}/preview`;

  return (
    <Space
      style={{ width: '100%', height: '100%', padding: 24, overflow: 'scroll' }}
      direction="vertical"
    >
      <ProgressSteps current={4} />
      <NextStepBar
        previousHref={`/cv/werknemer/${employeeId}/cvs/${resumeId}`}
      />
      <Switch>
        <Route
          path={`${basePath}/pdf/download`}
          exact
          render={() => (
            <PDFDownloadLink document={resumePdf}>
              {({ blob }) => {
                if (blob) {
                  saveAs(blob, resumeName);
                  setTimeout(() => history.replace(basePath), 3000);
                }
                return null;
              }}
            </PDFDownloadLink>
          )}
        />
        <Route
          path={`${basePath}/docx/download`}
          exact
          render={() => (
            <DocxResume resume={resume} language={lang}>
              {(blob) => {
                if (blob) {
                  saveAs(blob, `${resumeName}.docx`);
                  setTimeout(() => history.replace(basePath), 3000);
                }
              }}
            </DocxResume>
          )}
        />
      </Switch>
      <div className={classes.wrapper}>
        <div className={classes.form}>
          <Tabs defaultActiveKey="1" tabPosition="top" type="line" size="large">
            <Tabs.TabPane
              tab={t('resumePreview.sections.sections.title')}
              key="1"
            >
              <ResumeFineTuning resumeId={resumeId} onUpdate={handleUpdate} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('resumePreview.sections.order.title')} key="2">
              <ResumeOrderJobList resumeId={resumeId} onUpdate={handleUpdate} />
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className={classes.preview}>
          <DownloadBar
            basePath={basePath}
            anonymous={anonymous}
            onChangeAnonymous={() => setAnonymous(!anonymous)}
          />
          <PDFViewer height="100%" width="100%">
            {resumePdf}
          </PDFViewer>
        </div>
      </div>
    </Space>
  );
};
