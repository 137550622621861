import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from '@react-pdf/renderer';
import { Job as JobType } from '../../../../types/Resume.types';
import { Section, SectionProps } from '../../Section/Section';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { styles } from './Experience.styles';
import { Job } from './components/Job/Job';
import { List, ListItem } from '../../List';

export type ExperienceProps = {
  jobs: JobType[];
} & Pick<SectionProps, 'offset'>;

export const Experience: React.ComponentType<ExperienceProps> = ({
  jobs,
  offset,
}) => {
  const [t] = useTranslation();

  return (
    <Section offset={offset}>
      <SectionHeader>{t('resume.sections.experience.title')}</SectionHeader>
      <View>
        <List>
          {jobs.map((job, index) => (
            <ListItem
              key={index}
              lineFontSize={12}
              style={index > 0 ? styles.listItem : {}}
            >
              <Job {...job} />
            </ListItem>
          ))}
        </List>
      </View>
    </Section>
  );
};
