import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '../../../../components/AddRecordButton/AddRecordButton';
import DeleteButton from '../../../../components/DeleteRecordButton/DeleteRecordButton';
import CollectionTable from '../../../../components/CollectionTable/CollectionTable';
import useCollection from '../../../../hooks/useCollection';
import { Form } from '../../../../components/Form';
import { updateOrCreate } from '../../../../components/Form/helpers/createOrUpdate';
import {
  collection,
  EmployeeEducation,
  EmployeeResumeEducationType,
} from '../../../../models/EmployeeEducation';
import useQuery from '../../../../hooks/useQuery';
import { multiLingualColumn } from '../../../../components/CollectionTable/helpers/multiLingualColumn';

const columns: ColumnsType<EmployeeResumeEducationType> = [
  multiLingualColumn<EmployeeResumeEducationType>(
    {
      title: 'Opleiding',
      dataIndex: 'name',
      key: 'name',
    },
    'name'
  ),
  {
    title: 'Actie',
    dataIndex: 'id',
    key: 'x',
    render: (id) => <DeleteButton id={id} collection={collection} />,
  },
];

export type EmployeeEducationsProps = {
  employeeId?: string;
};

const EmployeeEducations: React.ComponentType<EmployeeEducationsProps> = ({
  employeeId,
}) => {
  const query = useQuery(EmployeeEducation).where(
    'employee_id',
    '==',
    employeeId
  );
  const [items] = useCollection(EmployeeEducation, query);

  return (
    <>
      <AddRecordButton
        model={EmployeeEducation}
        value="Nieuwe opleiding"
        initialValues={{ employee_id: employeeId }}
      />
      <CollectionTable
        values={items}
        columns={columns}
        Expander={({ value, onClose }) => (
          <Form
            onSubmit={(updatedValue) => {
              updateOrCreate(EmployeeEducation, updatedValue);
              onClose();
            }}
            onCancel={onClose}
            value={value}
            schema={EmployeeEducation.schema}
            schemaDescription={EmployeeEducation.schemaDescription}
            getFormProperties={() => EmployeeEducation.formProperties}
          />
        )}
      />
    </>
  );
};

export default EmployeeEducations;
