import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';
import { getValueInCurrentLanguage } from '../helpers/getValueInLanguage';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  resume_id: yup.string().required().label('CV ID'),
  jobTitle: yup.string().label('Rol'),
  introduction: multilingualSchema(yup.string()).label('Introductie'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
    hidden: true,
  },
  resume_id: {
    readonly: true,
    hidden: true,
  },
  introduction: {
    textarea: true,
  },
};

export const collection = 'employee_resume_general';

export const EmployeeResumeGeneral: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => getValueInCurrentLanguage(data.introduction),
};

export type EmployeeResumeGeneralType = RecordType;
