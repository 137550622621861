import React from 'react';
import { View, Canvas } from '@react-pdf/renderer';
import { styles } from './Section.styles';

export type SectionProps = {
  background?: boolean;
  offset?: number;
  children: React.ReactNode;
};

export const Section: React.ComponentType<SectionProps> = ({
  children,
  offset = 0,
  background = false,
}) => {
  const backgroundElement = (
    <Canvas
      style={styles.backgroundCanvas}
      paint={(painter, availableWidth, availableHeight): null => {
        painter.fillColor('#f8f8f8');
        painter.polygon(
          [0, 0],
          [availableWidth, 20],
          [availableWidth, availableHeight - 20],
          [0, availableHeight]
        );
        painter.fill();
        return null;
      }}
    />
  );

  const offsetStyle = { marginTop: offset };
  const wrapperStyles = background
    ? {
        ...styles.wrapper,
        ...styles.wrapperWithBackground,
        ...offsetStyle,
      }
    : { ...styles.wrapper, ...offsetStyle };

  return (
    <View style={wrapperStyles} wrap={true}>
      {background && backgroundElement}
      {children}
    </View>
  );
};
