import React from 'react';
import { Button, Col, Divider, Row, Space } from 'antd';
import { ValidationError, ObjectSchema, SchemaFieldDescription } from 'yup';
import { useTranslation } from 'react-i18next';
import { FormPropertiesType } from '../../models/Model.types';
import FormField, { FieldProps } from './fields';
import { fieldLabel } from './helpers/fieldLabel';

interface FormProps<T extends { id: string }> {
  readonly?: boolean;
  center?: boolean;
  changeHook?: (oldValue: T, newValue: T) => T | Promise<T>;
  onSubmit: (value: T) => void;
  onChange?: (value: T) => void;
  onCancel?: (value: T) => void;
  value: T | (() => T);
  schema: ObjectSchema<T>;
  schemaDescription: Record<string, SchemaFieldDescription>;
  getFormProperties: (record: T) => FormPropertiesType<T>;
  customFormFields?: {
    [key in keyof Partial<T>]: React.ComponentType<FieldProps<T>>;
  };
}

export const Form = <T extends { id: string; [key: string]: any }>({
  value,
  schema,
  schemaDescription,
  getFormProperties,
  changeHook,
  onSubmit,
  onCancel,
  onChange,
  customFormFields,
  center = true,
}: FormProps<T>) => {
  const [t] = useTranslation();
  const originalData = typeof value === 'function' ? value() : value;
  const [currentData, setCurrentData] = React.useState<T>(value);
  const handleChange = async (key: string, value: T) => {
    const newData = { ...currentData, [key]: value };
    if (onChange) {
      onChange(newData);
    }
    setCurrentData(
      changeHook ? await changeHook(currentData, newData) : newData
    );
  };

  const [errors, setErrors] = React.useState<Array<ValidationError>>([]);
  React.useEffect(() => {
    (async () => {
      try {
        await schema.validate(currentData, { abortEarly: false });
        setErrors([]);
      } catch (e) {
        setErrors((e as ValidationError).inner);
      }
    })();
  }, [currentData, schema]);
  const submit = () => onSubmit(currentData);
  const cancel = () => {
    onCancel && onCancel(currentData);
  };

  const isNew = !(originalData && originalData.id);

  const formProperties = getFormProperties
    ? getFormProperties(currentData)
    : ({} as Record<string, any>);

  const blockingErrors = errors.filter((error) => error.path !== 'id');

  const saveAllowed = blockingErrors.length === 0;

  const fields = Object.keys(schemaDescription)
    .filter((fieldName) => !formProperties[fieldName]?.hidden)
    .map((fieldName) => {
      const Field =
        customFormFields && fieldName in customFormFields
          ? customFormFields[fieldName]
          : FormField;
      return (
        <Row
          key={fieldName}
          style={{ marginBottom: '1em', flexFlow: 'nowrap' }}
        >
          <Col flex="0.5" style={{ maxWidth: 200, minWidth: 0 }}>
            <label htmlFor={fieldName}>
              {fieldLabel(schemaDescription, fieldName)}
            </label>
          </Col>
          <Col flex="1">
            <Field
              schemaDescription={schemaDescription[fieldName]}
              value={currentData[fieldName]}
              formProperty={formProperties[fieldName]}
              originalValue={originalData[fieldName]}
              onChange={(value: T) => handleChange(fieldName, value)}
              readonly={formProperties[fieldName]?.readonly}
              errors={errors.filter((err) =>
                err.path?.match(`^${fieldName}($|\\[\\d+\\]\\..*)`)
              )}
              rootValue={currentData}
            />
          </Col>
        </Row>
      );
    });

  return (
    <div
      style={
        center
          ? {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {}
      }
    >
      <div style={{ width: '100%', maxWidth: '700px' }}>
        {fields}
        <Divider />
        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button shape="round" onClick={cancel}>
            {t('form.cancel')}
          </Button>
          <Button
            disabled={!saveAllowed}
            shape="round"
            type="primary"
            onClick={submit}
          >
            {t(`form.${isNew ? 'create' : 'update'}`)}
          </Button>
        </Space>
      </div>
    </div>
  );
};
