import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  name: yup.string().required().label('Naam'),
  type: yup.string().required().label('Type'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
};

export const Skill: Model<RecordType> = {
  collection: 'skills',
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.name,
};

export type SkillType = RecordType;
