import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 2,
  },
  connected: {
    marginLeft: -3,
  },
  bullet: {
    width: 5,
    height: 5,
    borderRadius: 3,
  },
  primaryBullet: {
    backgroundColor: '#13ca91',
  },
  secondaryBullet: {
    backgroundColor: '#aaaaaa',
  },
  content: {
    paddingLeft: 12,
    paddingRight: 12,
  },
});
