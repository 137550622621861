import React from 'react';
import { Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './NextStepBar.module.css';

export type NextStepBarProps = {
  nextHref?: string;
  previousHref?: string;
};

export const NextStepBar: React.ComponentType<NextStepBarProps> = ({
  nextHref = '',
  previousHref = '',
}) => {
  const [t] = useTranslation();

  return (
    <div className={classes.wrapper}>
      <NavLink to={previousHref}>
        <Button disabled={!Boolean(previousHref)} shape="round" size="large">
          {t('navigation.previous')}
        </Button>
      </NavLink>
      <NavLink to={nextHref}>
        <Button
          type="primary"
          disabled={!Boolean(nextHref)}
          shape="round"
          size="large"
        >
          {t('navigation.next')}
        </Button>
      </NavLink>
    </div>
  );
};
