import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Person } from '../../../../types/Resume.types';
import { styles } from './Profile.styles';
import { Background } from './components/Background/Background';
import { ProfilePicture } from './components/ProfilePicture/ProfilePicture';
import { Logo } from '../../Logo/Logo';

export type ProfileProps = Pick<Person, 'jobTitle' | 'name' | 'imageURL'>;

export const Profile: React.ComponentType<ProfileProps> = ({
  jobTitle,
  name,
  imageURL,
}) => {
  return (
    <View style={styles.wrapper}>
      <Background style={styles.canvas} />
      <Logo style={styles.logo} width={102} color="white" />
      <ProfilePicture style={styles.imageWrapper} imageURL={imageURL!} />
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{name}</Text>
        <Text style={styles.subTitle}>{jobTitle}</Text>
      </View>
    </View>
  );
};
