import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem, SortableItemDummy } from './SortableListItem';

export type SortableItemType = {
  id: string | number;
  [key: string]: any;
};

export type SortableListProps<T> = {
  isTechstack?: boolean;
  renderItem: (item: T) => React.ReactElement;
  items: T[];
  onOrderUpdate: (items: T[]) => void;
};

export function SortableList<T extends SortableItemType>({
  items,
  renderItem,
  onOrderUpdate,
}: SortableListProps<T>): React.ReactElement {
  const [activeId, setActiveId] = useState<string | number | null>();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);

      onOrderUpdate(arrayMove(items, oldIndex, newIndex));
    }

    setActiveId(null);
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveId(active.id);
  }

  const activeItem = items.find((item) => item.id === activeId);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={items as any}
        strategy={verticalListSortingStrategy}
      >
        {items.map((item) => (
          <SortableItem key={item.id} id={item.id}>
            {renderItem(item)}
          </SortableItem>
        ))}
      </SortableContext>
      <DragOverlay>
        {activeItem ? (
          <SortableItemDummy id={activeItem.id}>
            {renderItem(activeItem)}
          </SortableItemDummy>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
