import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  employee_id: yup.string().required().label('Werknemer ID'),
  name: yup.string().label('Naam'),
  created_by: yup.string().label('Aanmaker'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  employee_id: {
    readonly: true,
    hidden: true,
  },
  created_by: {
    readonly: true,
    hidden: true,
  },
};

export const collection = 'employee_resume';

export const EmployeeResume: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => data.name!,
};

export type EmployeeResumeType = RecordType;
