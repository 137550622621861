import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  pairWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 300,
    paddingTop: 1,
    paddingBottom: 1,
  },
  label: {
    fontWeight: 700,
    marginRight: 6,
  },
  value: {
    maxWidth: 300,
  },
});
