import React from 'react';
import { Button, Select, Switch } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import classes from './DownloadBar.module.css';
import { useTranslation } from 'react-i18next';
import { setGlobalDateI18n } from 'fecha';

export type DownloadBarProps = {
  basePath: string;
  anonymous: boolean;
  onChangeAnonymous: () => void;
};

export const DownloadBar: React.ComponentType<DownloadBarProps> = ({
  basePath,
  anonymous,
  onChangeAnonymous,
}) => {
  const history = useHistory();
  const [t, i18n] = useTranslation();

  const setLanguage = (value: any) => {
    i18n.changeLanguage(value);
    setGlobalDateI18n(i18n.t('dates', { returnObjects: true }));
  };

  return (
    <div className={classes.wrapper}>
      <span>{t('downloadBar.chooseLanguage')}</span>
      <span>
        <Select
          onChange={setLanguage}
          defaultValue={'nl'}
          options={[
            { label: 'NL', value: 'nl' },
            { label: 'EN', value: 'en' },
          ]}
        />
      </span>
      <span>{t('downloadBar.anonymouseData')}</span>
      <span>
        <Switch checked={!anonymous} onChange={onChangeAnonymous} />
      </span>
      <span>{t('downloadBar.downloadFile')}</span>
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        shape="round"
        onClick={() => {
          history.replace(`${basePath}/pdf/download`);
        }}
      >
        PDF
      </Button>
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        shape="round"
        onClick={() => history.replace(`${basePath}/docx/download`)}
      >
        Docx
      </Button>
    </div>
  );
};
