import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';
import { getValueInCurrentLanguage } from '../helpers/getValueInLanguage';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  resume_id: yup.string().required().label('CV ID'),
  job_id: yup.string().required().label('Baan ID'),
  job_title: yup.string().required().label('Functie'),
  project_name: multilingualSchema(yup.string())
    .required()
    .label('Projectnaam'),
  description: multilingualSchema(yup.string())
    .required()
    .label('Omschrijving'),
  start_date: yup.date().nullable().label('Startdatum'),
  end_date: yup.date().nullable().label('Einddatum'),
  tech_stack: yup.array().of(yup.string()).label('Tech stack'),
  preview_url: yup.string().label('Preview URL'),
  repo_url: yup.string().label('Repo URL'),
  order: yup.number().nullable().label('Volgorde').default(1),
  logo: yup.string().label('Logo'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  resume_id: {
    readonly: true,
  },
  description: {
    textarea: true,
  },
  logo: {
    file: true,
    basePath: 'logo/',
  },
};

export const collection = 'employee_resume_project';

export const EmployeeResumeProject: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => getValueInCurrentLanguage(data.project_name),
};

export type EmployeeResumeProjectType = RecordType;
