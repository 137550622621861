import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    paddingLeft: 165,
    paddingRight: 30,
    paddingTop: 0,
    paddingBottom: 12,
  },
  wrapperWithBackground: {},
  backgroundCanvas: {
    position: 'absolute',
    top: -10,
    left: 150,
    bottom: -10,
    right: 0,
  },
});
