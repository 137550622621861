import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';
import { getValueInCurrentLanguage } from '../helpers/getValueInLanguage';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  employee_id: yup.string().required().label('Medewerker ID'),
  name: multilingualSchema(yup.string()).required().label('Opleiding'),
  school: multilingualSchema(yup.string()).required().label('School'),
  graduated: yup.boolean().required().label('Afgerond?'),
  profile: multilingualSchema(yup.string()).label('Profiel (NL)'),
  start_year: yup.number().required().label('Start jaar'),
  end_year: yup.number().required().label('Eind jaar'),
  note: multilingualSchema(yup.string()).label('Notitie'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  employee_id: {
    readonly: true,
  },
  graduated: {
    default: false,
  },
};

export const collection = 'employee_education';

export const EmployeeEducation: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => getValueInCurrentLanguage(data.name),
};

export type EmployeeResumeEducationType = RecordType;
