import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType, Model } from './Model.types';
import { multilingualSchema } from './helpers/multilingualSchema';

const schema = yup.object({
  id: yup.string().required().label('ID'),
  firstName: yup.string().required().label('Voornaam'),
  lastName: yup.string().required().label('Achternaam'),
  employeeNumber: yup.string().label('Werkn. nummer'),
  dateOfBirth: yup.date().label('Geboortedatum'),
  nationality: multilingualSchema(yup.string()).label('Nationaliteit'),
  cityOfResidence: yup.string().label('Woonplaats'),
  githubUrl: yup.string().label('Github'),
  profileImage: yup.string().label('Profielfoto'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
  id: {
    readonly: true,
  },
  profileImage: {
    file: true,
    basePath: 'profile-images/',
  },
};

export const collection = 'employees';

export const Employee: Model<RecordType> = {
  collection,
  schema,
  schemaDescription: schema.describe().fields,
  formProperties,
  defaultDisplay: (data) => `${data.firstName} ${data.lastName}`,
};

export type EmployeeType = RecordType;
