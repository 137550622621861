import { firestore } from 'firebase';
import { Model } from '../models/Model.types';
import { firebaseApp } from '../services/firebase';

export const useQuery = <T extends object>(model: Model<T>) => {
  const query = firebaseApp
    .firestore()
    .collection(model.collection) as firestore.CollectionReference<T>;

  return query;
};

export default useQuery;
