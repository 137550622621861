import React from 'react';
//@ts-ignore
import Flag from 'react-world-flags';
import { Tabs } from 'antd';
import { FieldProps } from '.';
import { LANGUAGES } from '../../../constants/languages';
import {
  MultiLingualValueType,
  getValueInLanguage,
} from '../../../helpers/getValueInLanguage';

export type MultiLingualFieldsProps<T = any> = FieldProps<T> & {
  FieldComponent: React.ComponentType<FieldProps<any>>;
};

const MultiLingualField: React.ComponentType<
  MultiLingualFieldsProps<MultiLingualValueType[]>
> = ({ value = [], onChange, readonly, FieldComponent, ...restProps }) => {
  const handleChange = (newValue: string, language: string) => {
    const oldValue = getValueInLanguage(value, language);
    const newValueArray =
      typeof oldValue !== 'undefined'
        ? value.map((item) =>
            item.language === language ? { ...item, value: newValue } : item
          )
        : [
            ...value,
            {
              language,
              value: newValue,
            },
          ];

    onChange(newValueArray);
  };

  return (
    <Tabs defaultActiveKey="1" type="line" size="small" animated={false}>
      {LANGUAGES.map((language, index) => (
        <Tabs.TabPane
          tab={<Flag style={{ width: 24 }} code={language.flag} />}
          key={(index + 1).toString()}
        >
          <FieldComponent
            {...restProps}
            value={getValueInLanguage(value, language.language) || ''}
            onChange={(innerValue: string) =>
              handleChange(innerValue, language.language)
            }
            readonly={readonly}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default MultiLingualField;
