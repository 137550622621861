import React from 'react';
import { Space, Tabs } from 'antd';
import Skills from './Skills/Skills';
import Characteristics from './Characteristics/Characteristics';
import Languages from './Languages/Languages';

export const Data = () => {
  return (
    <Space
      style={{ width: '100%', height: '100%', padding: 24, overflow: 'scroll' }}
      direction="vertical"
    >
      <Tabs
        items={[
          {
            key: '0',
            label: 'skills',
            children: <Skills />,
          },
          {
            key: '1',
            label: 'Kenmerken',
            children: <Characteristics />,
          },
          {
            key: '2',
            label: 'Talen',
            children: <Languages />,
          },
        ]}
      />
    </Space>
  );
};

export default Data;
