import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  wrapper: {
    fontFamily: 'Inter',
    lineHeight: 1.5,
  },
  normal: {
    fontWeight: 300,
    fontSize: 10,
  },
  large: {
    fontWeight: 400,
    fontSize: 12,
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
});
