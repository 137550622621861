/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Avatar, Dropdown, Row, Col } from 'antd';

import logo from '../../assets/codecapi_logo.png';
import useSession from '../../hooks/useSession';
import { signOut } from '../../hooks/useAuth';
import { NavLink } from 'react-router-dom';

export const Header = () => {
  const user = useSession();

  return (
    <Row
      style={{
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Col>
        <NavLink to="/">
          <img height={20} src={logo} alt="" />
        </NavLink>
      </Col>

      <Col style={{ flex: 1 }} />

      <Col style={{ fontWeight: 100 }}>
        <Dropdown
          menu={{
            items: [
              {
                type: 'group',
                label: user?.displayName ?? '',
                children: [
                  {
                    key: '1',
                    label: (
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://console.cloud.google.com/logs?project=codecapi-resume"
                        style={{ marginRight: '2em' }}
                      >
                        Logs
                      </a>
                    ),
                  },
                  {
                    key: '2',
                    label: (
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://console.firebase.google.com/u/3/project/codecapi-resume/database1"
                        style={{ marginRight: '2em' }}
                      >
                        Firestore
                      </a>
                    ),
                  },
                  {
                    key: '3',
                    label: (
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://console.firebase.google.com/u/3/project/codecapi-resume/storage"
                        style={{ marginRight: '2em' }}
                      >
                        Storage
                      </a>
                    ),
                  },
                  {
                    key: '4',
                    label: <div onClick={signOut}>Log out</div>,
                  },
                ],
              },
            ],
          }}
          trigger={['click']}
        >
          <div style={{ cursor: 'pointer', flex: 1 }}>
            <Avatar src={user?.photoURL || ''} />
          </div>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default Header;
