import { SkillListType } from '../components/SkillList/SkillList';
import { Skill, SkillType } from '../models/Skill';
import useCollection from './useCollection';
import { Skills as SkillsType } from '../types/Resume.types';
import { useMemo } from 'react';

export const useSelectedSkills = (
  skills: SkillListType[] | SkillsType
): [(SkillType & SkillListType)[] | undefined, boolean?] => {
  const [allSkills, isDone] = useCollection(Skill);

  const selectedSkills = useMemo(() => {
    if (!isDone) return undefined;

    return skills?.map((skill) => {
      const originalSkill = allSkills.find((item) => {
        return typeof skill === 'string'
          ? item.name === skill
          : item.id === skill.id;
      });

      if (!originalSkill) {
        throw new Error(
          `Skill with ID ${
            typeof skill === 'string' ? skill : skill.id
          } not found`
        );
      }

      return { ...originalSkill };
    });
  }, [isDone, skills, allSkills]);

  return [selectedSkills, isDone];
};
