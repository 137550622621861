import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { FieldProps } from '.';

const DateField = ({
  value,
  onChange,
  readonly,
}: FieldProps<string | null>) => {
  return (
    <div>
      <DatePicker
        format="DD-MM-YYYY"
        disabled={readonly}
        value={value ? dayjs(value) : null}
        onChange={(value) =>
          value
            ? onChange(dayjs(value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            : onChange(null)
        }
      />
    </div>
  );
};

export default DateField;
